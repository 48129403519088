import moment from "moment";
import {AxiosResponse} from "axios";
import {
    illegalIandaChars,
    illegalTwitterChars,
    illegalTwitterContentChars,
    illegalTwitterHandleChars,
    fileExtensionMatcher,
    fileUriPrefixMatcher,
    minTwitterHandleLength,
    maxTwitterHandleLength,
    noCountryCodeUSPhoneNumber,
    pasteSocialContentInputSplitter,
    pasteSocialHandleInputSplitter
} from "./regex-matchers";
import {ListParameters} from "Store/store";
// import {SocialFollow} from "Store/persona";
import {Error} from 'Store/store';
import Vue from "vue";
// import { Operators } from "Store/state-types";
// import {PhotoCharacteristics} from "Store/account/account";
import { RequestError, Status } from "./immutables";

export const instagram: string = 'instagram';
export const ImageFallbackSource = {
    twitter: 'https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/images/Twitter_logo_blue_48.png',
}
export const passwordMaskPlaceholder: string = '*********';
export const securityQuestions: Array<string> = [
    '',
    `What is your maternal grandmother's maiden name?`,
    `What was the name of your first stuffed animal?`,
    `What were the year, make and model of your first car (e.g. 1969 Ford Mustang)?`,
    `What is your oldest sibling's middle name?`,
    `What is your oldest child's middle name?`,
    `What was the first concert you attended?`,
    `Who is your favorite fictitious character?`,
    `What was your childhood phone number including area code (e.g. 5555555555)?`,
    `What was the name of your first company you worked for?`,
    `Who is your significant other's/spouse's favorite fictitious character?`,
    `What was the name of your elementary school?`,
    `Where was your father born?`,
    `What is your favorite cartoon?`,
    `What was the street you grew up on?`,
    `What is the furthest place you've traveled to?`,
    `What is your father's middle name?`,
    `Where does your nearest sibling live?`,
    `What is the first and last name of your first boyfriend or girlfriend?`,
    `What was your favorite place to visit as a child?`,
    `What is the first name of your oldest nephew?`,
    `What was the name of your favorite teacher?`,
    `What was your grandfather's occupation?`,
    `What is the first and last name of your best childhood friend?`,
    `What is your grandmother's first name?`,
    `What was your favorite restaurant in college?`,
    `What is the first name of your oldest niece?`,
    `What was your childhood nickname?`,
    `What hospital were you born in?`,
    `What is your favorite book?`,
]
export const slugIgnoredCharacters = /[^ a-zA-Z0-9_\-]/g;
export const twitter: string = 'twitter';

export interface DateOptions {
    format?: string,
    timeZone?: string,
    utc?: boolean
}

// export interface RectCoords {
//     x: number;
//     y: number;
//     width: number;
//     height: number;
// }

/** FUNCTIONS **/

export const axiosResponse = () => {
    let responseObj: AxiosResponse<any> = {data: {}, status: 200, statusText: '', headers: {}, config: {}};

    return responseObj;
};

export const bindClickOutClose = (event: Event): void => {
    let clickOutKey: string = (<HTMLElement>event.target).getAttribute('clickoutkey') || '';

    if ((<HTMLElement>event.target).classList.contains('click-out-close')) {
        return;
    } else {
        // If clicked element is a clild of a click-out-close element, its in target so do nothing
        if ((<HTMLElement>event.target).closest('.click-out-close')) {
            return;
        } else {
            // Clicked element is not a click-out-close element nor in one
            let clickOutClosers = document.getElementsByClassName('click-out-close') as HTMLCollectionOf<HTMLElement>;
            let isEventElementClickOutSafe = ((<HTMLElement>event.target).classList.contains('click-out-safe'));

            for (let i = 0; i < clickOutClosers.length; i++) {
                if (isEventElementClickOutSafe) {
                    // Clicked element is not a click-out-close element, nor in one, is click-out-safe
                    if (clickOutKey === clickOutClosers[i].getAttribute('clickoutkey')) {
                        // Clicked element isn't paired with click-out-closer
                        return;
                    } else {
                        clickOutClosers[i].style.display = 'none';
                    }
                } else {
                    clickOutClosers[i].style.display = 'none';
                }
            }

            return;
        }
    }
}

export const caseInsensitiveEquals = (str1: string, str2: string): boolean => {
    let result: number = str1?.localeCompare(str2, undefined, {sensitivity: 'base'});

    switch (result) {
        case 0:
            return true;
        default:
            return false;
    }
}

// export const caseInsensitiveIncludes = ( value: string, searchString: string | string[], operator?: string ) => {
//     const casedValue: string = value ? value.toLowerCase() : '';
//     let casedSearchString: string = '';
//     let includes: boolean = false;
//     operator = operator ? operator : Operators.OR;
//
//     if ( typeof searchString === 'string' ) {
//         casedSearchString = searchString.toLowerCase();
//         includes = casedValue.includes( casedSearchString );
//     } else if ( Array.isArray( searchString) ) {
//         for ( let i = 0; i < searchString.length; i++ ) {
//             casedSearchString = searchString[ i ].toLowerCase();
//             includes = casedValue.includes( casedSearchString );
//
//             if ( operator === Operators.AND ) {
//                 if ( !includes ) return false;
//             } else if ( operator === Operators.OR ) {
//                 if ( includes ) return true;
//             }
//         }
//     }
//
//     return includes;
// }

// export const clickElementById = ( elementId: string ) => {
//     ( <HTMLElement>document.getElementById( elementId ) ).click();
// }

export const closeAllModals = ( exceptions: string[] ) => {
    // get all modals
    const modals: HTMLCollectionOf<Element> = document.getElementsByClassName( 'modal' );

    if (modals && modals.length > 0 ) {
        // on every modal change state like in hidden modal
        for ( let i = 0; i < modals.length; i++ ) {
            if ( !modals[ i ].id || !exceptions.includes( modals[ i ].id ) ) {
                modals[ i ].classList.remove( 'show' );
                modals[ i ].setAttribute( 'aria-hidden', 'true' );
                modals[ i ].setAttribute( 'style', 'display: none' );
            }
        }
    }

    // get modal backdrops
    const modalsBackdrops: HTMLCollectionOf<Element> = document.getElementsByClassName('modal-backdrop');

    if ( modalsBackdrops && modalsBackdrops.length > 0 ) {
        // remove every modal backdrop
        for ( let i = 0; i < modalsBackdrops.length; i++ ) {
            modalsBackdrops[ i ].remove();
        }
    }
}

// export const closeOpenModals = () => {
//     let openModals = document.getElementsByClassName('modal show') as HTMLCollectionOf<HTMLElement>;
//     // TODO: Need to come back to this
// }

/**
 * Copy the given text to the clipboard
 * @param text
 */
export const copyText = async (text: string) => {
    let result = false;

    await navigator.clipboard.writeText(text)
        .then(_ => {
            result = true;
        })
        .catch(err => {
            console.error('Could not copy text to clipboard', err);
        });

    return result;
};

export const dataElementPageLocations = (pages: any[], dataElementId: string, params: any) => {
    let pageMatches: any[] = [];
    for (const page of pages) {
        for (const category of page.categories) {
            const categoryElementIds = category.dataElements.map((el: any) => el.dataElementDisplayId);
            if (categoryElementIds.includes(dataElementId)) {
                const pageDescription = page.pageDescription;
                const categoryDescription = category.categoryDescription;
                pageMatches.push({
                    route: {
                        name: params.routeName,
                        params: {
                            token: params.token,
                            tab: slug(pageDescription),
                            section: slug(categoryDescription),
                        },
                        hash: `#${dataElementId}`,
                    },
                    pageDescription,
                    categoryDescription,
                })
            }
        }
    }

    return pageMatches;
};

export const dateFormat = (val: Date, options?: DateOptions) => {
    let formattedDate: string;

    options = Object.assign({}, {
        format: 'standard'
        // timeZone: 'America/Denver'
    }, options);

    let pattern;
    let patternNameTest = RegExp('^[a-zA-Z]+$');
    if (patternNameTest.test(options.format || '')) {
        switch (options.format) {
            // Add patterns here as needed
            case 'date':
                pattern = 'M/D/YYYY';
                break;
            case 'englishDate':
                pattern = 'MMM D, YYYY';
                break;
            case 'fullEnglishDate':
                pattern = 'MMMM D, YYYY';
                break;
            case 'fullEnglishDateTime':
                pattern = 'MMMM D, YYYY \\a\\t h:mm A';
                break;
            case 'fileDate':
                pattern = 'YYYY-MM-DD hmmA'; // e.g. 2019-01-30 315PM
                break;
            case 'isoShort':
                pattern = 'YYYY-MM-DD'; // e.g. 2019-01-30
                break;
            case 'standard':
            default:
                pattern = 'YYYY-MM-DD h:mm A'; // e.g. 2019-01-30 3:15 PM
        }
    } else {
        // Use the supplied format as-is
        pattern = options.format;
    }

    formattedDate = moment(val).format(pattern);

    return formattedDate;
};

export const decodeHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
}

export const deleteCookie = (name: string): void => {
    const expires: string = moment().utc().add(1, 'year').toString();
    document.cookie = `${name}=; expires=${expires}`;
}

export const deleteObjectProperties = ( obj: any, attributes: string[] ): void => {
    try {
        if ( obj && attributes && Array.isArray( attributes ) ) {
            for ( let i = 0; i < attributes.length; i++ ) {
                if ( obj.hasOwnProperty( attributes[ i ] ) ) {
                    delete( obj[ attributes[ i ] ] );
                }
            }
        }
    } catch ( error ) {
        console.error( error );
    }
}

// export const demographicIconComponents = (photoCharacteristics: PhotoCharacteristics) => {
//     let iconComponents: any = {};
//
//     // Determine age bracket
//     switch (photoCharacteristics.age_range) {
//         case 'F':
//         case 'G':
//         case 'H':
//         case 'I':
//             iconComponents.age = 'middle-aged';
//             break;
//         case 'J':
//         case 'K':
//         case 'L':
//         case 'M':
//             iconComponents.age = 'elderly';
//             break;
//         default:
//             iconComponents.age = 'young';
//     }
//
//     // Determine urbanicity
//     switch (photoCharacteristics.county_size) {
//         case 'A':
//             iconComponents.countySize = 'urban'
//             break;
//         case 'B':
//             iconComponents.countySize = 'suburban'
//             break;
//         case 'C':
//         case 'D':
//             iconComponents.countySize = 'rural'
//             break;
//     }
//
//     // Determine gender
//     switch (photoCharacteristics.gender) {
//         case 'F':
//             iconComponents.gender = 'female'
//             break;
//         case 'M':
//             iconComponents.gender = 'male'
//             break;
//         default:
//             iconComponents.gender = 'other'
//     }
//
//     iconComponents.childrenPresent = photoCharacteristics.children_present_in_hh === '1';
//     iconComponents.married = photoCharacteristics.marital_status === 'M';
//
//     return iconComponents;
// }

/**
 * Dereference (clone) an array or object to allow handling it in a non-destructive fashion
 *
 * @param data
 */
export const dereference = data => {
    return JSON.parse(JSON.stringify(data));
};

/**
 * Dynamically retrieve a descendent property of an object
 *
 * EXAMPLE:
 * descendentProp(
 *     {
 *         value: {
 *             shortDescription: 'Hi',
 *             longDescription: 'Hello, World!',
 *         }
 *     },
 *     'value.longDescription'
 * )
 * ^ returns 'Hello, World!'
 *
 *
 * @param obj
 * @param propertyPath
 */
export const descendentProp = (obj: object, propertyPath: string) => {
    return propertyPath.split('.').reduce((a, b) => a.hasOwnProperty(b) ? a[b] : `UNKNOWN PROPERTY "${b}" in ${propertyPath}`, obj);
};

export const doNothing = () => {
    // Do nothing function for moot events
}

export const filterErrorType = (errors: Array<Error>, type: string) => {
    if (!isEmptyArray(errors)) {
        return errors.filter((error) => {
            return error.type !== type
        });
    } else {
        return [];
    }
}

export const formatPhoneNumber = (str: string) => {
    let formatted: string = '';
    let cleaned: string = ('' + str).replace(/\D/g, '');

    if (str !== '') {
        let matched: boolean = noCountryCodeUSPhoneNumber.test(cleaned);

        if (matched) {
            formatted = `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
        }
    }

    return formatted;
};

/**
 * Format a numeric value for human readability
 *
 * @param value
 * @param format
 * @param decimalPlaces
 */
export const formatValue = (value: number | string, format: string = '', decimalPlaces: number = 2): string|number => {
    if (value === undefined || value === null) {
        return value;
    }
    value = Number(value);

    switch (format) {
        case 'currency':
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            });

        case 'decimal':
        default:
            return value.toLocaleString('en-US', {
                maximumFractionDigits: decimalPlaces,
                minimumFractionDigits: decimalPlaces,
            });
            // return value.toFixed(decimalPlaces);

        case 'indexLikelihood':
            const amount = value > 2 ?
                formatValue(Math.round(value), 'separated') :
                formatValue(indexPercent(value), 'percent', 0);
            const indexText = value > 2 ?
                'x as' :
                value > 1 ?
                    ' more' :
                    ' less';

            return `${amount}${indexText} likely`;

        case 'percent':
            return (value * 0.01).toLocaleString('en-US', {
                style: 'percent',
                minimumFractionDigits: decimalPlaces
            });

        case 'separated':
            return value.toLocaleString('en-US', {
                style: 'decimal',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: decimalPlaces
            });
    }
};

export const fragmentLink = (uniqueIdentifier: string) => {
    return slug(stripHtml(uniqueIdentifier).replace(slugIgnoredCharacters, ''));
}

export const getActivateInactivateIcon = (check: boolean): string[] => {
    if (check) {
        return ['far', 'times'];
    } else {
        return ['fas', 'plus-circle']
    }
}

export const getCheckCircleStatusIcon = (check: boolean): string[] => {
    if (check) {
        return ['fas', 'check-circle'];
    } else {
        return ['far', 'circle']
    }
}

export const getCheckSquareStatusIcon = (check: boolean): string[] => {
    if (check) {
        return ['fas', 'check-square'];
    } else {
        return ['far', 'square']
    }
}

export const getPermission = ( setting: number | boolean | null ): boolean => {
    if ( setting ) {
        if ( typeof setting === 'boolean' ) {
            return setting;
        } else if ( typeof setting === 'number' ) {
            return setting > 0
        } else {
            throw `${RequestError.INVALID_REQUEST}: getPermission`;
        }
    } else {
        return false;
    }
}

export const getPermissionIcon = ( setting: number | boolean | null ) => {
    const allowed: boolean = getPermission( setting );
    return ( allowed ) ? [ 'fas', 'check' ] : [];
}

export const getStatusToggleIcon = ( status: boolean | null ): string[] => {
    return ( status ) ? [ 'fad', 'toggle-on' ] : [ 'fad', 'toggle-off' ];
}

export const getCookieValue = (name: string): string => {
    let value: string = '';

    try {
        value = document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    } catch (error) {
        console.error(error.stack);
    }

    return value;
}

export const getDictionaryIcon = (dictionary: any, fieldName: string): string[] => {
    if (!dictionary.hasOwnProperty(fieldName)) {
        return [];
    }

    return [dictionary[fieldName]['iconStyle'], dictionary[fieldName]['iconName'].replace(/^fa-/, '')];
}

export const getFileExtensionByName = (name: string) => {
    if (name) {
        return (name.match(fileExtensionMatcher) || [])[0].replace(/\./g, '');
    } else {
        return 'error';
    }
}

export const getFileIcon = (fileType: string) => {
    switch (fileType) {
        case 'csv':
        case 'text/csv':
            return 'file-csv';
        case 'xlsx':
            return 'file-excel';
        case 'pdf':
            return 'file-pdf';
        case 'image':
        case 'text/image':
            return 'file-image';
        default:
            return 'file-alt';
    }
}

export const getNextSibling = function (element: HTMLElement, selector: string) {
    let sibling = element.nextElementSibling;

    // If the sibling matches selector, use it, otherwise try next sibling and continue
    while (sibling) {
        if (sibling.matches(selector)) return (sibling as HTMLElement);
        sibling = sibling.nextElementSibling
    }
};

export const getNonPrefixedFileUri = (uri: string): string => {
    let validUri: string = uri;

    if (fileUriPrefixMatcher.test(uri)) {
        validUri = uri.replace(fileUriPrefixMatcher, '');
    }

    return validUri;
}

export const getPastedSocialInputArray = async (event: ClipboardEvent, type: string) => {
    let pastedInputArray: Array<string> = [];

    if (event.type === 'paste' && (event.clipboardData) instanceof DataTransfer) {
        let pastedInput: any = event.clipboardData.getData('text') || '';
        let splitInput: Array<string> = (type === 'handles') ? pastedInput.split(pasteSocialHandleInputSplitter) : pastedInput.split(pasteSocialContentInputSplitter);

        for (let i = 0; i < splitInput.length; i++) {
            pastedInputArray.push(splitInput[i]);
        }
    }

    return pastedInputArray;
}

export const getSiblings = function (element: HTMLElement): Array<HTMLElement> {
    let siblings: Array<HTMLElement> = [];

    if (element) {
        let sibling: HTMLElement = <HTMLElement>element.parentNode?.firstChild;

        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== element) {
                siblings.push(sibling);
            }

            sibling = <HTMLElement>sibling.nextSibling;
        }
    }

    return siblings;
};

export const howLongAgo = (val: Date) => {
    return moment(val).fromNow();
};

// /**
//  * Convert a topic name to a more readable format
//  * e.g. "Media-Mainstream_News" -> "Media - Mainstream News"
//  *
//  * @param categoryName
//  * @param subcategorySeparator
//  * @deprecated
//  */
// export const humanReadableCategoryName = (categoryName: string, subcategorySeparator: string = ' - ') => {
//     const name: string = categoryName ? categoryName : '';
//     const [category, subcategory] = name.split('-');
//     let categoryLabel = category.replace(/_/g, ' ');
//     if (subcategory) {
//         categoryLabel += `${subcategorySeparator}${subcategory.replace(/_/g, ' ')}`;
//     }
//
//     return categoryLabel.replace(/\s+/g, ' ');
// };

// export const humanReadableFilename = ( name: string ) => {
//     if ( name ) {
//         const extension = ( name.match( fileExtensionMatcher ) || [] )[ 0 ];
//         return name.replace( extension, '' );
//     } else {
//         return 'error';
//     }
// }

export const humanReadableFileTypeList = (list: string) => {
    return list.replace(/image\/|text\/|application\/|\+xml/g, '').replace(/,/g, ', ').toUpperCase();
}

export const humanReadableName = (firstName: string, lastName: string, middleNameOrInitial?: string) => {
    if (firstName && lastName) {
        let middle: string = middleNameOrInitial ? middleNameOrInitial : ' ';
        return `${firstName}${middle}${lastName}`;
    } else {
        return 'error';
    }
}

export const imageFallback = (source: string) => {
    return `this.setAttribute('data-original-src', this.src);this.src='${ImageFallbackSource[source] || source}';this.onerror='';this.setAttribute('data-img-error', true)`;
};

export const indexPercent = (index: number) => {
    if (index < 1) {
        return (1 - index) * -100;
    }

    return (index - 1) * 100;
};

export const isClientError = (code: number) => {
    return code > 300 && code < 500;
}

export const isEmptyArray = (array: Array<any>) => {
    return (!Array.isArray(array) || !array.length || (array.length === 0));
};

export const isEmptyObject = (obj: any): boolean => {
    if (obj) {
        return Object.keys(obj).length < 1;
    } else {
        return true;
    }
};

export const isLastItemInObject = (index: number, obj: Object) => {
    return index === Object.keys(obj).length - 1;
};

export const isLastItemInArray = (index: number, array: any[]) => {
    return index === array.length - 1;
};

// export const isLegalHashtag = (hashtag: string) => {
//     const checker: RegExp = illegalHashtagChars;
//     let legalHashtag: boolean = true;
//
//     if (checker.test(hashtag)) {
//         legalHashtag = false;
//     }
//
//     return legalHashtag;
// }

export const isLegalTwitterHandle = (handle: string) => {
    if (illegalTwitterHandleChars.test(handle)) {
        return false;
    }

    // Make sure handle isn't an invalid length
    const handleWithoutAt = handle.replace('@', '');
    if (handleWithoutAt.length < minTwitterHandleLength || handleWithoutAt.length > maxTwitterHandleLength) {
        return false;
    }

    return true;
}

export const isLegalTwitterContent = (content: string) => {
    // @fdjwdfjhwbfiuw7
    const checker: RegExp = illegalTwitterContentChars;
    let legalContent: boolean = true;

    if (checker.test(content)) {
        legalContent = false;
    }

    return legalContent;
}

export const isNumeric = (value: any) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export const isString = (value: any) => {
    return typeof value === 'string' || value instanceof String;
}

export const isValidUserAccount = (accountId: string, userAccounts: Array<any>): boolean => {
    let valid: boolean = false;

    for (let i = 0; i < userAccounts.length; i++) {
        if (accountId === userAccounts[i].id) {
            valid = true;
            break;
        }
    }

    return valid;
}

export const isValidLastPath = (path: string): boolean => {
    return !['/login', '/404'].includes(path);
}

/**
 * Convert camelCase to kebab-case
 * @param val
 */
export const kebabCase = (val: string) => {
    return val.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

export const lcFirst = (val: string) => {
    return val.charAt(0).toLowerCase() + val.slice(1);
};

// export const normalizePort = (val: string) => {
//     const port = parseInt(val, 10);
//
//     if (isNaN(port)) {
//         // named pipe
//         return val;
//     }
//
//     if (port >= 0) {
//         return port;
//     }
//
//     return false;
// };

/**
 * Convert a numeric value to alpha
 * e.g. 1 -> A, 2 -> B, 26 -> Z
 *
 * @param value
 * @param uppercase
 */
export const numberToAlpha = (value: number, uppercase: boolean = true) => {
    if (value < 1 || value > 26) {
        return null;
    }

    let alpha = (value + 9).toString(36);
    if (uppercase) {
        alpha = alpha.toUpperCase();
    }

    return alpha;
}

export const orderObjectByKey = (obj: any, order: Array<string>) => {
    let newObject = {};

    for (let i = 0; i < order.length; i++) {
        if (obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]];
        }
    }

    return newObject;
}

export const pascalCase = (value: string) => {
    if (value) {
        return value.replace(/\w\S*/g, m => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase())
    } else {
        return value;
    }
}

// /**
//  * Detect collision between two rectangles
//  * @url https://stackoverflow.com/a/16012490/2208000
//  *
//  * @param {RectCoords} rectA
//  * @param {RectCoords} rectB
//  */
// export const rectanglesIntersect = (rectA: RectCoords, rectB: RectCoords): boolean => {
//     const aLeftOfB = rectA.x + rectA.width < rectB.x;
//     const aRightOfB = rectB.x + rectB.width < rectA.x;
//     const aAboveB = rectA.y + rectA.height < rectB.y;
//     const aBelowB = rectB.y + rectB.height < rectA.y;
//
//     return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
// };

export const resetActiveNavItems = () => {
    const targetElement: HTMLElement = <HTMLElement>document.getElementById( 'navbarSupportedContent' );

    if ( targetElement ) {
        let activeElements = targetElement.querySelectorAll('.active');

        if ( activeElements ) {
            for (let i = 0; i < activeElements.length; i++) {
                activeElements[i].classList.remove(Status.ACTIVE);
            }
        }
    }
}

// export const safeParseInt = ( str: string ): number => {
//     let value: number = 0;
//
//     try {
//         value = parseInt( str );
//     } catch ( error ) {
//         console.error( error.stack );
//     }
//
//     return value;
// }

export const saveRequestFailedError = (objName: string, action?: string) => {
    action = action ? action : 'saved';
    return `Something went wrong. <br />Your ${objName} could not be ${action}. Please try again later.`;
}

export const scrollToListItemByClass = (parentId: string, className: string) => {
    let targetListItem: HTMLElement = (<HTMLElement>document.querySelector(`#${parentId} .${className}`));

    if (targetListItem) {
        let menu: HTMLElement = <HTMLElement>targetListItem.parentNode;
        let scroll: number = menu.scrollTop;
        let scrollBottom: number = menu.clientHeight - targetListItem.clientHeight;
        let scrollTop: number = menu.scrollHeight - menu.clientHeight;

        if (targetListItem.offsetTop > (scrollBottom + scroll)) {
            Vue.nextTick(() => {
                menu.scrollTo(0, scroll + targetListItem.clientHeight);
            });
        } else if (targetListItem.offsetTop < (scrollTop + targetListItem.clientHeight)) {
            Vue.nextTick(() => {
                menu.scrollTo(0, scroll - targetListItem.clientHeight);
            });
        }
    }
}

export const scrollToHash = (hash: string) => {
    if (!hash.length) {
        return;
    }

    let hashElement: HTMLElement | null = document.querySelector(hash);
    hashElement?.scrollIntoView( {
        behavior: 'smooth',
        block: 'center'
    } );
}

/**
 * Convert camelCase to kebab-case
 * @param val
 */
export const sentenceCase = (val: string) => {
    const words: string[] = val.split(' ');

    return words
        .map((word, index) => {
            let casedWord = word.length > 1 && word.toUpperCase() === word ? // Only lowercase non-acronyms
                word :
                word.toLowerCase();

            return index === 0 && casedWord !== word ?
                casedWord.charAt(0).toUpperCase() + casedWord.slice(1).toLowerCase() :
                casedWord;
        })
        .join(' ');
    // return val;
    // return val.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

/**
 * @name            setActive
 * @description     Sets an element to an active class/state based on key and mouse events
 * @param event     The event
 * @param element   If the event.target is not the target to set active
 * @param callback  Callback function
 */
export const setActive = function (event: Event, element?: HTMLElement, callback?: any): void {
    let targetElement = <HTMLElement>element ? <HTMLElement>element : <HTMLElement>event.target;

    const activate = function () {
        let activeElements = targetElement.parentNode?.querySelectorAll('.active');

        if (activeElements) {
            for (let i = 0; i < activeElements.length; i++) {
                activeElements[i].classList.remove(Status.ACTIVE);
            }
        }

        targetElement.classList.add(Status.ACTIVE);

        if (callback) {
            callback();
        }
    }

    if (event.type.indexOf('mouse') !== -1) {
        // Confirm there was mouse movement to avoid key event causing mouse event
        targetElement.onmousemove = function () {
            activate();
        }
    } else if (event.type.indexOf('key') !== -1) {
        let keyEvent: KeyboardEvent = <KeyboardEvent>event;

        // Confirm keys were arrow keys
        if (keyEvent.key.indexOf('arrow')) {
            activate();
        }
    } else if (event.type.indexOf('submit') !== -1) {
        activate();
    }
}

export const setCookie = (name: string, value: string, expireDate?: string): void => {
    const expires: string = expireDate ? `expires=${expireDate}` : '';
    document.cookie = `${name}=${value}; ${expires}`;
}

export const setNextSiblingActive = function (event: Event, activeElement: HTMLElement, selector: string) {
    let sibling = activeElement.nextElementSibling;

    // If the sibling matches selector set to active, otherwise try next sibling and continue
    while (sibling) {
        if (sibling.matches(selector)) {
            const nextSibling = getNextSibling(<HTMLElement>activeElement, selector);
            if (nextSibling) {
                setActive(event, nextSibling);
            }
        }

        sibling = sibling.nextElementSibling
    }
};

export const setTagInputFocus = (input: any) => {
    // vue-tag-input is a wrapped input so we must ref the wrapper, then the embedded newTagInput
    (<HTMLElement>input.$refs.newTagInput).focus();
}

export const siteRoot = (): string => {
    const url = window.location;

    return `${url.protocol}//${url.host}/`;
};

/**
 * Generate a slug from a given string
 * e.g. "This is My New Title!" -> "this-is-my-new-title"
 * @param val
 */
export const slug = (val: string) => {
    return val
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') // Change any non-alphanumeric characters to a hyphen
        .replace(/(^-*|-*$)/g, ''); // Remove extraneous hyphens
};

export const socialAvatarIcon = (follow: any, platform?: string) => {
    let avatarIcon: string = '';

    if (follow.positive) {
        switch (platform) {
            case instagram :
                // Just twitter for now
                avatarIcon = '';
                break;
            default :
                avatarIcon = `url( ${socialProfileUrl(follow)}/profile_image?size=normal )`;
                break;
        }
    }

    return avatarIcon;
}

export const socialContentIcon = (socialContent: any) => {
    const socialContentString: boolean = typeof socialContent === 'string';
    let character: string = '';
    let icon: string;

    if (socialContentString) {
        character = socialContent.charAt(0);
    } else {
        if (socialContent.hasOwnProperty('content')) {
            character = socialContent.content.charAt(0);
        } else if (socialContent.hasOwnProperty('twitterContent')) {
            character = socialContent.twitterContent.charAt(0);
        }
    }

    switch (character) {
        case '@':
            icon = 'at';
            break;
        case '#':
            icon = 'hashtag';
            break;
        default:
            icon = 'quote-left';
            break;
    }

    return icon;
}

export const socialContentText = (socialContent: any) => {
    const socialContentString: boolean = typeof socialContent === 'string';
    let text: string = '';

    if (!socialContentString) {
        if (socialContent.hasOwnProperty('content')) {
            text = socialContent.content;
        } else if (socialContent.hasOwnProperty('twitterContent')) {
            text = socialContent.twitterContent;
        }
    }

    return text;
}

export const socialContentType = (socialContent: any) => {
    const socialContentString: boolean = typeof socialContent === 'string';
    let character: string = '';
    let type: string;

    if (socialContentString) {
        character = socialContent.charAt(0);
    } else {
        if (socialContent.hasOwnProperty('content')) {
            character = socialContent.content.charAt(0);
        } else if (socialContent.hasOwnProperty('twitterContent')) {
            character = socialContent.twitterContent.charAt(0);
        }
    }

    switch (character) {
        case '@':
            type = 'mentions';
            break;
        case '#':
            type = 'hashtags';
            break;
        default:
            type = 'terms';
            break;
    }

    return type;
}

// export const socialFollowName = (follow: SocialFollow, platform?: string) => {
//     const handleObj: boolean = typeof follow === 'object';
//     let name: string = '';
//
//     switch (platform) {
//         case instagram :
//             const instagramTitle: string = follow.instagramTitle || '';
//             name = (handleObj) ? instagramTitle || escape(instagramTitle) : '';
//             break;
//         default :
//             name = (handleObj) ? follow.name || escape(follow.twitterHandle) : '';
//             break;
//     }
//
//     name = (name !== 'undefined') ? name : '';
//
//     return name;
// }

// export const socialProfileUrl = (follow: SocialFollow, platform?: string) => {
//     const handleObj: boolean = typeof follow === 'object';
//     const host: string = `https://${twitter}.com/`; // Just twitter for now
//     let handleText: string = '';
//
//     handleText = (handleObj) ? follow.twitterHandle?.replace(/^@/g, '') : '';
//
//     return `${host}${handleText}`;
// }

export const socialToggleState = (negativesAllowed: boolean, social: any) => {
    if (negativesAllowed) {
        return social.positive;
    } else {
        return true;
    }
}

// // TODO: can we factor this out by adapting it to use sortByProperty instead?
// export const sortArray = (data: Array<any>, params: ListParameters, caseSensitive?: boolean) => {
//     caseSensitive = (typeof caseSensitive === 'boolean') ? caseSensitive : true;
//
//     switch (params.order) {
//         case 'asc':
//             data = data.sort(function (a, b) {
//                 if (a[params.sort] && b[params.sort]) {
//                     if (caseSensitive) {
//                         return ((a[params.sort] == b[params.sort]) ? 0 : ((a[params.sort].toLowerCase() > b[params.sort].toLowerCase()) ? 1 : -1));
//                     } else {
//                         return ((a[params.sort] == b[params.sort]) ? 0 : ((a[params.sort] > b[params.sort]) ? 1 : -1));
//                     }
//                 }
//             });
//
//             break;
//         case 'desc':
//             data = data.sort(function (a, b) {
//                 if (a[params.sort] && b[params.sort]) {
//                     if (caseSensitive) {
//                         return ((a[params.sort] == b[params.sort]) ? 0 : ((a[params.sort].toLowerCase() < b[params.sort].toLowerCase()) ? 1 : -1));
//                     } else {
//                         return ((a[params.sort] == b[params.sort]) ? 0 : ((a[params.sort] < b[params.sort]) ? 1 : -1));
//                     }
//                 }
//             });
//
//             break;
//     }
//
//     return data;
// }

/**
 * Sort an array of arrays by one or more indices
 * @param data
 * @param sortIndices
 * @param direction = 'asc'
 */
export const sortArray = (data: [], sortIndices: number | number[], direction: string = 'asc') => {
    if (data === undefined) {
        return data;
    }

    const forward = 1,
        backward = -1;
    if (typeof sortIndices === 'number') sortIndices = [sortIndices];

    let sortedElements = data.sort((a, b) => {
        let sortResult = 0;

        // @ts-ignore
        for (let sortIndex of sortIndices) {
            const propA = `${a[sortIndex]}`;
            const numPropA = /[a-z]/i.test(propA) ? propA : +(propA.replaceAll(/[^0-9]+/g, ''));
            const propB = `${b[sortIndex]}`;
            const numPropB = /[a-z]/i.test(propB) ? propB : +(propB.replaceAll(/[^0-9]+/g, ''));

            if (isNumeric(numPropA) && isNumeric(numPropB)) {
                // Use numeric comparison
                if (numPropA !== numPropB) {
                    sortResult = numPropA > numPropB ?
                        forward :
                        backward;
                    break;
                }
            }

            // Use string comparison
            sortResult = propA.localeCompare(propB);
        }

        return sortResult;
    });

    return direction === 'asc' ? sortedElements : sortedElements.reverse();
};

/**
 * Sort an array of objects by one or more properties
 * @param data
 * @param properties
 * @param direction = 'asc'
 */
export const sortByProperty = (data: any[], properties: string | string[], direction: string = 'asc') => {
    if (data === undefined || properties === null) {
        return data;
    }

    const forward = 1,
        backward = -1;
    if (typeof properties === 'string') properties = [properties];
    // const originalData = dereference(data);

    let sortedObjects = data.sort((a, b) => {
        let sortResult = 0;

        for (let property of properties) {
            if (!a.hasOwnProperty(property)) {
                a[property] = 0;
            }
            if (!b.hasOwnProperty(property)) {
                b[property] = 0;
            }

            const propA = `${a[property]}`;
            const numPropA = +propA;
            const propB = `${b[property]}`;
            const numPropB = +propB;

            if (isNumeric(numPropA) && isNumeric(numPropB)) {
                // Use numeric comparison
                if (numPropA !== numPropB) {
                    sortResult = numPropA > numPropB ?
                        forward :
                        backward;
                    break;
                }
            }

            // Use string comparison
            sortResult = propA.localeCompare(propB);
        }

        return sortResult;
    });

    return direction === 'asc' ? sortedObjects : sortedObjects.reverse();
};

export const stripHtml = (html: string) => {
    let tmp = document.createElement('div');
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || '';
}

export const tagString = (val: string) => {
    return val.replace(/[^a-z0-9]+/ig, '_').replace(/^_*([a-z0-9_]+[a-z0-9]+)_*$/, '$1');
};

export const titleCase = (val: string) => {
    try {
        return val.toLowerCase()
            .split(' ')
            .map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            })
            .join(' ');
    } catch (error) {
        console.error(error);
        return null;
    }
};

// export const trimIllegalHashtagChars = (value: string) => {
//     if (value) {
//         return '#' + value.replace(illegalTwitterChars, '');
//     } else {
//         return value;
//     }
// }

export const trimIllegalIandaChars = (value: string) => {
    if (value) {
        return value.replace(illegalIandaChars, '');
    } else {
        return value;
    }
}

export const trimIllegalTwitterContentChars = (value: string) => {
    if (value) {
        const firstChar = value.trim().substr(0, 1);
        const contentPrefixChar: RegExp = RegExp(/^@|^#/g);

        return firstChar.match(contentPrefixChar) ?
            firstChar + value.replace(illegalTwitterChars, '') :
            value;
    } else {
        return value;
    }
}

export const trimIllegalTwitterHandleChars = (handle: string, includeAt: boolean = true) => {
    if (handle) {
        return (includeAt ? '@' : '') + handle.replace(illegalTwitterChars, '').substr(0, maxTwitterHandleLength);
    } else {
        return handle;
    }
}

export const trimLeadingForwardSlash = (val: string) => {
    return val.replace(/^\/+/, '');
};

export const ucFirst = (val: string) => {
    return val.charAt(0).toUpperCase() + val.slice(1);
};

export const uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};
