import axios from 'axios';
import Highcharts from 'highcharts';
import clone from 'lodash-es/clone';
import BaseColors from 'Utilities/colors';
import * as Utils from 'Utilities/utils';

// export const NON_CHART_PREFIX = 'NON-GRAPH::';
export const PLOT_WIDTH_BAR_STACKED = 24;
export const HEADER_SIZE_BAR_STACKED = 87;
export const MAX_INTEGER_VALUE = 999999999999;
// export const MAX_SUBCATEGORY_COUNT = 15; // Maximum number of subcategories that will be visible in a chart

// export const demographicElementPattern = new RegExp(`^demographic:(column|donut):(.*)$`, 'i');
export const dynamicElementPattern = new RegExp(`^([a-z0-9-]+):([a-z0-9-]+):(bar|column|donut|map):(.*)$`, 'i');
export const dynamicSummaryPattern = new RegExp(`^([a-z0-9-]+):(summary)$`, 'i');
export const mainSummaryCharacteristicPattern = new RegExp(`^main_summary_characteristic:([a-z0-9-]+)$`, 'i');
export const mainSummaryCharacteristicsAllPattern = new RegExp(`^main_summary_characteristics_all:([a-z0-9,]+):(asc|desc)$`, 'i');

export const dataElementDescriptor = 'Characteristic';
export const infinityDescriptor = '<i class="far fa-fw fa-infinity"></i>';
export const linearityLiftScoreDescriptor = 'Linearity and Lift Score';
export const lowValueDescriptor = 'Low';
export const medianValueDescriptor = 'Median';
export const percentBaselineDescriptor = '<span title="% of Baseline">Base %</span>';
export const percentOverlayDescriptor = '<span title="% of Customer File">Cust. %</span>';
export const zScoreDescriptor = 'Z-Score';

export const topoJsonIdentityDuplicateIndicator = '_dupe_';
export const topoJsonIdentityProperty = 'shape_id';

export const chartEvents = {
    excludeDataElement: 'exclude-data-element',
};
export const dataElementTypes = {
    categorical: 'CATEGORICAL',
    continuous: 'CONTINUOUS',
    index: 'INDEX',
    numerical: 'NUMERICAL',
};

const outOfRangeLabel = `<em>NOT IN RANGE</em>`;

export type ComparisonDataSortOptions = {
    filterGroup: string,
    filterSettings: any,
    matchProperty: string,
    sortGroup: string
};
export const dataElementCategories = (dataElements: any[], matchProperty?: string, detailProperty?: string): any[] => {
    return dataElements.map((element: any) => {
        matchProperty = matchProperty || 'displayIndexScore';
        detailProperty = detailProperty || 'indexScore';
        let label = element.shortDescription.trim();

        const detailItemOptions = element.statsDetail.filter((detail: any) => detail[detailProperty] === element[matchProperty]);
        let topBin: any = null;
        if (detailItemOptions.length > 1) {
            // Choose the highest matching bin
            topBin = Utils.sortByProperty(detailItemOptions, 'binId', 'desc')[0];
        } else if (detailItemOptions.length === 1) {
            // This is the bin
            topBin = detailItemOptions[0];
        }
        if (topBin) {
            label = `${label}:<br><strong>${topBin.description?.trim()}</strong>`;
        }

        return label;
    });
};
export const dataElementCategoryPointFormatter = (point: any, data: any[], chartId: string): string => {
    return `<div class="d-flex align-items-center flex-row-reverse">
        <div class="">${dataElementExcludeLink(data[point.pos], chartId)}</div>
        <div class="text-right">${point.value}</div>
    </div>`;
};
export const dataElementExcludeHandler = (e: any) => {
    const action = e.target.getAttribute('data-action');
    if (action === chartEvents.excludeDataElement) {
        const chartEvent = new CustomEvent('chart-action', {
            detail: {
                action,
                chartId: e.target.getAttribute('data-chart-id'),
                context: e.target.getAttribute('data-context'),
                dataElementId: e.target.getAttribute('data-element-id'),
            },
        })
        document.querySelector(`[data-intent="pda-report"]`)!.dispatchEvent(chartEvent);
    }
};
export const dataElementExcludeLink = (element: any, chartId: string = '', context: string = 'summary') => {
    return `
        <i class="text-danger fa fas fa-times-square pl-2" 
           data-action="${chartEvents.excludeDataElement}" 
           data-chart-id="${chartId}" 
           data-context="${context}" 
           data-element-id="${element.dataElementId}"
           title="Exclude ${element.dataElementId} from summary charts. Excluded elements can be updated on the report settings tab."
        ></i>
    `.trim();
};
// export const territoryCodes = ['AS','GU','PR'];
export const usAlaskaHawaiiInsets = [
    {
        id: 'us-alaska',
        borderWidth: 0,
        field: {
            type: 'Polygon',
            coordinates: [
                [
                    [0, 60],
                    [25, 80],
                    [25, 100],
                    [0, 100]
                ]
            ]
        },
        geoBounds: {
            type: 'Polygon',
            coordinates: [
                [
                    [-179.5, 50],
                    [-129, 50],
                    [-129, 72],
                    [-179.5, 72]
                ]
            ]
        },
        padding: ['30%', '5%', 0, 0],
        projection: {
            name: 'LambertConformalConic',
            parallels: [55, 65],
            rotation: [154]
        }
    },
    {
        id: 'us-hawaii',
        borderWidth: 0,
        field: {
            type: 'Polygon',
            coordinates: [
                [
                    [25, 80],
                    [40, 92],
                    [40, 100],
                    [25, 100]
                ]
            ]
        },
        geoBounds: {
            type: 'Polygon',
            coordinates: [
                [
                    [-162, 23],
                    [-152, 23],
                    [-152, 18],
                    [-162, 18]
                ]
            ]
        },
        padding: ['30%', '20%', 0, '10%'],
        projection: {
            name: 'LambertConformalConic',
            parallels: [8, 18],
            rotation: [157]
        }
    }
];
export const usTerritoryInsets = [
    {
        id: 'us-puerto-rico',
        isTerritory: true,
        borderWidth: 0,
        field: {
            type: 'Polygon',
            coordinates: [
                [
                    [56, 82],
                    [56, 91],
                    [68, 91],
                    [68, 82]
                ]
            ]
        },
        geoBounds: {
            type: 'Polygon',
            coordinates: [
                [
                    [-68, 19],
                    [-68, 17],
                    [-65, 17],
                    [-65, 19]
                ]
            ]
        },
        projection: {
            name: 'LambertConformalConic',
            parallels: [18],
            rotation: [68]
        }
    },
    // Hide all other territories
    // {id: 'us-guam', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-samoa', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-mariana-1', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-mariana-2', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-virgin-1', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-virgin-2', isTerritory: true, borderWidth: 0, units: 'pixels'},
];
export const usFocusTerritoryInsets = [].concat(usAlaskaHawaiiInsets as [], usTerritoryInsets as []);

export const heatmapStops = [
    [0, '#4466a4'], // Nick's blue-75
    [0.15, BaseColors.teal['75']],
    [0.40, BaseColors.green['75']],
    [0.60, '#fbe35f'], // Nick's yellow-75
    [0.85, BaseColors.orange['75']], // orange-75
    [1, BaseColors.red['75']], // red-75
];
export const reportColors = {
    axis: {
        low: {
            min: BaseColors.red['75'],
            max: BaseColors.red['25'],
        },
        high: {
            min: BaseColors.green['25'],
            max: BaseColors.green['75'],
        },

        min: 0,
        minColor: BaseColors.red['75'],
        maxColor: BaseColors.green['75'],
    },
    barIndex: {
        baselineRatio: BaseColors.blue['25'],
        count: BaseColors.blue['75'],
        countGrid: BaseColors.blue['10'],
        countLabel: BaseColors.blue['100'],
        index: BaseColors.green['75'],
        indexGrid: BaseColors.green['10'],
        indexLabel: BaseColors.green['100'],
        linearityLiftScore: BaseColors.orange['75'],
        linearityLiftScoreLabel: BaseColors.orange['100'],
        overlayRatio: BaseColors.blue['75'],
    },
    barStacked: [
        BaseColors.blue['75'],
        BaseColors.orange['75'],
        BaseColors.green['75'],
        BaseColors.purple['75'],
        BaseColors.teal['75'],
    ],
    // comparisonBar: {
    //     baseline: BaseColors.blue['25'],
    //     0: BaseColors.blue['75'],
    //     1: BaseColors.yellow['100'],
    // },
    comparison: {
        baseline: BaseColors.blue['25'],
        index: BaseColors.orange['75'],
        filter: {
            index: 'green',
            overlayRatio: 'teal',
            groupPenetrationRatio: 'purple',
        },
        sort: {
            independent: 'orange',
            0: 'blue',
            1: 'yellow',
        },
        0: BaseColors.blue['75'],
        1: BaseColors.yellow['100'],
    },
    choropleth: {
        states: {
            hover: {
                color: BaseColors.blue['75'],
                borderColor: BaseColors.blue['25'],
            }
        },
    },
    donut: {
        standard: [
            BaseColors.blue['75'],
            BaseColors.yellow['75'],
            BaseColors.teal['75'],
            BaseColors.orange['75'],
            BaseColors.purple['75'],
            BaseColors.red['75'],
            BaseColors.green['75'],

            BaseColors.yellow['25'],
            BaseColors.teal['50'],
            BaseColors.orange['25'],
            BaseColors.purple['25'],
            BaseColors.red['50'],
            BaseColors.green['25'],

            BaseColors.blue['100'],
            BaseColors.yellow['100'],
            BaseColors.teal['100'],
            BaseColors.orange['100'],
            BaseColors.purple['100'],
            BaseColors.red['100'],
            BaseColors.green['100'],
        ],
        baselineRatio: [
            BaseColors.blue['50'],
            BaseColors.yellow['50'],
            BaseColors.teal['50'],
            BaseColors.orange['50'],
            BaseColors.purple['50'],
            BaseColors.red['50'],
            BaseColors.green['50'],
        ],
        overlayRatio: [
            BaseColors.blue['75'],
            BaseColors.yellow['75'],
            BaseColors.teal['75'],
            BaseColors.orange['75'],
            BaseColors.purple['75'],
            BaseColors.red['75'],
            BaseColors.green['75'],
        ]
    },
    gauge: {
        low: BaseColors.red['75'],
        mid: BaseColors.yellow['75'],
        high: BaseColors.green['75'],
    },
    heatmap: {
        labels: {
            formatter: function () {
                return `${Utils.formatValue(this['value'], 'percent')}`;
            },
        },
        stops: heatmapStops,
        endOnTick: true,
        startOnTick: true,
        minColor: '#FFF',
        states: {
            hover: {
                // color: BaseColors.gray['25'],
                color: BaseColors.gray['100'],
                borderColor: BaseColors.gray['25'],
            }
        },
    },
    indexBreadthDepthSummary: {
        breadth: BaseColors.blue['75'],
        breadthLabel: BaseColors.blue['100'],
        depth: BaseColors.yellow['75'],
        depthLabel: BaseColors.yellow['100'],
        index: BaseColors.green['75'],
        indexLabel: BaseColors.green['100'],
    },
    wedge: {
        fill: BaseColors.yellow['100'],
        fillSequence: [
            BaseColors.yellow['100'],
            BaseColors.orange['100'],
            BaseColors.purple['100'],
            BaseColors.teal['100'],
            BaseColors.green['100'],
            BaseColors.red['100'],
        ],
        stripes: BaseColors.gray['25'],
    },
    zone: {
        positiveNegative: [
            {
                value: 0,
                color: BaseColors.red['75'],
            },
            {
                color: BaseColors.green['75'],
            }
        ],
        relativeIndex: [
            {
                value: 1,
                color: BaseColors.red['75'],
            },
            {
                color: BaseColors.green['75'],
            }
        ],
    }
};
export const chartAxisOptions = {
    bar: [
        {
            min: 0,
            title: {
                text: null
            },
            labels: {
                format: '{value}%',
            },
        }
    ],
    barGroupPercent: [
        {
            min: 0,
            title: {
                text: 'Percent of Group'
            },
            labels: {
                format: '{value}%',
            },
        },
    ],
    barIndex: [
        {
            min: 0,
            title: {
                text: 'Depth of Interest'
            },
            labels: {
                format: '{value}%',
            },
        },
        {
            title: {
                text: 'Relative Index'
            },
            opposite: true,
        }
    ],
    barIndexGroupPercent: [
        {
            min: 0,
            title: {
                text: 'Percent of Group'
            },
            labels: {
                format: '{value}%',
            },
        },
        {
            title: {
                text: 'Relative Index'
            },
            opposite: true,
        }
    ],
    relativeIndex: [
        {
            title: {
                text: 'Relative Index'
            },
            plotLines: [{
                color: reportColors.comparison.index,
                value: 1,
                width: 2,
            }],
        },
    ],
    sIndex: [
        {
            title: {
                text: 'Relative Index'
            },
        }
    ],
};
export const chartDataLabelOptions = {
    map: {
        // allowOverlap: true,
        // backgroundColor: 'rgba(255, 255, 255, .4)',
        // color: '#000000',
        backgroundColor: 'rgba(0, 0, 0, .4)',
        color: '#ffffff',
        // crop: false,
        // overflow: 'none',
        padding: 3,
        useHTML: true,
        verticalAlign: 'bottom',
    },
};
export const chartPlotOptions = {
    bar: {
        column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
        }
    },
    barGroup: {
        series: {
            groupPadding: 0,
        },
        column: {
            grouping: true,
            shadow: false,
            borderWidth: 0,
        },
    },
    barPositiveNegative: {
        bar: {
            zones: reportColors.zone.relativeIndex,
        },
    },
    barStacked: {
        bar: {
            stacking: 'normal',
        },
    },
    donut: {
        pie: {
            allowPointSelect: true,
            // borderWidth: 0.5,
            // borderColor: 'transparent',
            center: ['50%', '50%'],
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            },
            shadow: false,
            showInLegend: true,
            size: '100%',
            startAngle: 270,
            states: {
                hover: {
                    brightness: 0,
                    halo: null,
                },
                inactive: {
                    opacity: 0.5,
                },
            },
        }
    },
};
export const defaultChartOptions = {
    chart: {
        backgroundColor: 'transparent',
    },
    title: {
        style: {
            color: BaseColors.gray['75]'],
            fontSize: '18px',
            fontWeight: 'normal',
        },
        useHTML: true,
        widthAdjust: -10,
    },
    tooltip: {
        outside: true,
        shared: true,
        useHTML: true,
    },
    credits: {
        enabled: false,
    },
    responsive: {}
};
// export const iconPath = {
//     // personaBuilder: '../../../../assets/images/logo-icon-only-gray-75.svg',
//     wiland: '../../../../assets/images/wiland-logo-icon-only-gray-75.svg',
// };

/* Functions */

const baseOutlines = async (includeTerritories: boolean = false, returnRawData: boolean = false) => {
    let baseOutlineData = require(includeTerritories ?
        '@highcharts/map-collection/countries/us/custom/us-all-territories.topo.json' :
        '@highcharts/map-collection/countries/us/us-all.topo.json'
    );

    baseOutlineData.objects.default.geometries = baseOutlineData.objects.default.geometries
        .filter((feature: any) => {
            // Take out any areas that are not necessary for our mapping needs (i.e. US states + Puerto Rico)
            return feature.id.match(/^(?:US\.|PR.)/);
        })
    // .map((feature: any) => {
    //     // Fix South Carolina / St. Croix collision issue
    //     if (feature.properties['hc-a2'] === 'SC' && feature.properties['type'] !== 'State') {
    //         feature.properties['hc-a2'] = 'SCX';
    //     }
    //
    //     return feature;
    // })
    ;

    return returnRawData ? baseOutlineData : formatOutlineSeries(baseOutlineData);
}
export {baseOutlines}

/**
 * Get ChartAssociatedData parameters for the data item with the greatest difference between multiple sets
 *
 * @param title
 * @param detail
 * @param data
 * @param property
 * @param labelProperty
 */
    // export const chartAssociatedGreatestDifference = (
    //     title: string,
    //     detail: string,
    //     data: any[],
    //     property: string = 'index',
    //     labelProperty: string = 'value'
    // ) => {
    //     // property = property || 'index';
    //     // labelProperty = labelProperty || 'value';
    //     let differences: any[] = [];
    //     // console.warn(`🧮🟢 CHART ASSOCIATED GREATEST DIFFERENCE (${property}): ${title} --- ${detail}`, data);
    //
    //     // Step 1: Calculate the differences for each item
    //     let i = 0;
    //     for (const dataSegment of data) {
    //         // console.warn('DATA SEGMENT:', dataSegment);
    //         for (const item of dataSegment.series) {
    //             let differenceItemIndex = differences.findIndex((existingItem: any) => existingItem.index === item.originalValue);
    //             if (differenceItemIndex > -1) {
    //                 // Update the difference if applicable
    //                 const differenceItem = differences[differenceItemIndex];
    //                 if (item[property] < differenceItem.minValue) {
    //                     differenceItem.minIndex = i;
    //                     differenceItem.minValue = item[property];
    //                 }
    //                 if (item[property] > differenceItem.maxValue) {
    //                     differenceItem.maxIndex = i;
    //                     differenceItem.maxValue = item[property];
    //                 }
    //                 differenceItem.difference = Math.abs(differenceItem.minValue - differenceItem.maxValue);
    //             } else {
    //                 // Add the new item
    //                 differences.push({
    //                     index: item.originalValue,
    //                     minIndex: i,
    //                     minValue: item[property],
    //                     maxIndex: i,
    //                     maxValue: item[property],
    //                 });
    //             }
    //         }
    //         ++i;
    //     }
    //     // console.warn(`🧮🟡 ${title} DIFFERENCES:`, differences);
    //
    //     // Step 2: Return the greatest difference
    //     const greatestDifference: any = Utils.sortByProperty(differences, 'difference', 'desc')[0];
    //     const direction = greatestDifference.minIndex > greatestDifference.maxIndex ? 'left' : 'right';
    //     let value = greatestDifference.difference;
    //     if (property === 'index') {
    //         // Convert to a percentage
    //         value *= 100;
    //     }
    //     // let value = (greatestDifference.difference / (
    //     //         greatestDifference.minIndex < greatestDifference.maxIndex ?
    //     //             greatestDifference.minValue :
    //     //             greatestDifference.maxValue
    //     //     )
    //     // ) * 100;
    //     if (value === Infinity) {
    //         value = 100
    //     }
    //     const firstData = data[0];
    //     const lastData = data[data.length - 1];
    //
    //     // console.warn(`🧮🔴 ${title} DIFFERENCE VALUE: ${value} (property: ${property})`, greatestDifference, firstData, lastData);
    //
    //     const positive_label = property === 'index' ? 'higher' : 'more';
    //     const negative_label = property === 'index' ? 'lower' : 'less';
    //     const baseline_data = firstData.series.find((dataItem: any) => dataItem.originalValue === greatestDifference.index);
    //     const baseline_value = baseline_data?.hasOwnProperty(property) ? baseline_data[property] : 0;
    //     const persona_data = lastData.series.find((dataItem: any) => dataItem.originalValue === greatestDifference.index);
    //     const persona_value = persona_data?.hasOwnProperty(property) ? persona_data[property] : 0;
    //     const value_format = property === 'overlayRatio' ? 'percent' : 'decimal',
    //         value_label = firstData.series.find((dataItem: any) => dataItem.originalValue === greatestDifference.index)[labelProperty];
    //
    //     // console.warn('VALUE LABEL:', value_label, data[0].find((dataItem: any) => dataItem.originalValue === greatestDifference.index))
    //
    //     return {
    //         title,
    //         detail,
    //         detail_class: greatestDifference.minIndex > greatestDifference.maxIndex ? 'value-greater' : 'value-less',
    //         detail_format: 'decimal',
    //         detail_format_decimals: 0,
    //         direction,
    //         value,
    //         value_format,
    //         value_label,
    //         positive_label,
    //         negative_label,
    //         // baseline_label: `Persona ${Utils.numberToAlpha(1)}`,
    //         baseline_label: firstData.label,
    //         // persona_label: `Persona ${Utils.numberToAlpha(data.length)}`,
    //         persona_label: lastData.label,
    //         baseline_value,
    //         persona_value,
    //         data_invalid: isNaN(value),
    //     };
    // }

    // /**
    //  * Get ChartAssociatedData parameters for the data item with the greatest value
    //  *
    //  * @param title
    //  * @param detail
    //  * @param data
    //  * @param property
    //  */
    // export const chartAssociatedGreatestValue = (title: string, detail: string, data, property: string = 'index') => {
    //     let greaterKey = 0;
    //     let greaterValue = 0;
    //     for (let i = 0, end = data.length; i < end; ++i) {
    //         const currentValue = data[i][property];
    //         if (currentValue > greaterValue) {
    //             greaterValue = currentValue;
    //             greaterKey = i;
    //         }
    //     }
    //     const greaterData = data[greaterKey];
    //     const value: number = parseFloat((100 * (greaterData.index - 1)).toFixed(1));
    //     // const direction = value >= 0 ? 'more likely' : 'less likely';
    //
    //     return {
    //         title,
    //         detail,
    //         detail_format: 'decimal',
    //         detail_format_decimals: 1,
    //         value,
    //         value_label: greaterData.value.toLowerCase(),
    //         positive_label: 'more likely',
    //         negative_label: 'less likely',
    //         baseline_label: `${greaterData.value} in Baseline`,
    //         persona_label: `${greaterData.value} in Customer File`,
    //         baseline_value: greaterData.baselineRatio,
    //         persona_value: greaterData.overlayRatio,
    //     };
    // }

export const chartDataToSeries = (type: string, data: any, options: any = {}) => {
        if (!data) {
            return false;
        }

        options = Object.assign({
            chartLabels: {},
            property: null,
        }, options);
        options.chartLabels = Object.assign({
            baseline: 'Baseline',
            overlay: 'Customer File',
        }, options.chartLabels);

        switch (type) {
            case 'bar':
                return [
                    {
                        formatter: (value: any) => {
                            return `${value.toFixed(2)}%`;
                        },
                        name: options.chartLabels.baseline,
                        type: 'column',
                        // yAxis: 0,
                        color: reportColors.barIndex.baselineRatio,
                        data: data.map((item: any) => item.baselineRatio)
                        // data: data.map((item: any) => item.baseline)
                    },
                    {
                        formatter: (value: any) => {
                            return `${value.toFixed(2)}%`;
                        },
                        name: options.chartLabels.overlay,
                        type: 'column',
                        pointPadding: 0.3,
                        pointPlacement: 0,
                        // yAxis: 0,
                        color: reportColors.barIndex.overlayRatio,
                        data: data.map((item: any) => item.overlayRatio)
                    }
                ];

            case 'barIndex':
                return [
                    {
                        formatter: (value: any) => {
                            return `${value.toFixed(2)}%`;
                        },
                        name: options.chartLabels.baseline,
                        type: 'column',
                        yAxis: 0,
                        color: reportColors.barIndex.baselineRatio,
                        // data: data.map((item: any) => item.baselineRatio),
                        data: data.map((item: any) => item.baselineValue),
                    },
                    {
                        formatter: (value: any) => {
                            return `${value.toFixed(2)}%`;
                        },
                        name: options.chartLabels.overlay,
                        type: 'column',
                        pointPadding: 0.3,
                        pointPlacement: 0,
                        yAxis: 0,
                        color: reportColors.barIndex.overlayRatio,
                        // data: data.map((item: any) => item.overlayRatio),
                        data: data.map((item: any) => item.overlayValue),
                    },
                    {
                        name: 'Relative Index',
                        type: 'line',
                        yAxis: 1,
                        color: reportColors.barIndex.index,
                        zones: reportColors.zone.relativeIndex,
                        data: data.map((item: any) => item.hasOwnProperty('index') ? item.index : item.indexScore),
                    }
                ];

            case 'donut':
                return data.map((item, index) => {
                    return {
                        name: item.label,
                        y: item[options.property],
                        // i: index,
                        color: reportColors.donut['standard'][index]
                    }
                });

            // case 'donutSingle':
            //     return data.map((item, index) => {
            //         return {
            //             name: item.value,
            //             y: item[property],
            //             color: reportColors.donut.baselineRatio[index]
            //         }
            //     });

            case 'sIndex':
                return [
                    {
                        name: 'Relative Index',
                        showInLegend: false,
                        type: 'bar',
                        zones: reportColors.zone.positiveNegative,
                        data: data.map((item: any) => item.index)
                    }
                ];
        }
    }

/**
 * Render overlay data onto a chart
 * TODO: should this be a base method that can be extended in Persona/Audience report?
 *
 * @param chart
 * @param overlayType
 * @param clearElements
 */
export const chartOverlayCallback = (chart: any, overlayType: string, clearElements: boolean = true) => {
    if (clearElements) {
        // Remove existing overlay data on this chart, in case of redraw callbacks
        chart.container.querySelectorAll('[intent="overlay-data"]')?.forEach((element: any) => {
            element.parentNode.removeChild(element);
        });
    }

    switch (overlayType) {
        case 'consumerSpendSummary':
        case 'pastPurchaseSummary': {
            const chartPadding = 10,
                chartWidth = chart.plotBox.width;
            const groupContainer = chart.userOptions.rawData,
                indicatorPercent = Math.round(groupContainer.overlayRatio),
                indexPercent = groupContainer.index > 2 ?
                    Math.round(groupContainer.index) :
                    Utils.formatValue(Utils.indexPercent(groupContainer.index), 'percent', 0);

            // Move the legend just below the gauge
            const ringBottom = chart.pane[0].center[1] + (chart.plotBox.width / 2);
            chart.legend.destroy();
            chart.legend.options.y = ringBottom + chartPadding;
            chart.legend.render();

            // Render the slider
            const indexText = groupContainer.index > 2 ?
                'x as' :
                groupContainer.index > 1 ?
                    ' more' :
                    ' less';
            chart.renderer
                .text(
                    `<div class="text-center value-${groupContainer.index > 1 ? 'greater' : 'less'}"
                          style="width: ${chartWidth - (chartPadding * 2)}px; font-size: 10px;">
                        ${indexPercent}${indexText} ${chart.userOptions.sliderPhrase}
                    </div>
                    `,
                    chartPadding,
                    chart.plotBox.y + chartPadding / 2,
                    true
                )
                .attr({
                    intent: 'overlay-data',
                })
                .add()
            chart.renderer
                .text(
                    `
                    <div class="group-size-display mb-2"
                         style="width: ${chartWidth - (chartPadding * 2)}px"
                         title="${indicatorPercent}% ${chart.userOptions.tooltipPhrase}"
                    >
                        <div class="group-size-indicator" data-toggle="tooltip"
                             style="left: ${indicatorPercent}%"
                        >&#9650;
                        </div>
                    </div>
                    `,
                    chartPadding,
                    chart.plotBox.y + chartPadding * 2,
                    true
                )
                .attr({
                    intent: 'overlay-data',
                })
                .add()
        }
            break;

        case 'geographicReport': // Fix label positioning so they do not exceed the bounds of the visible chart
            for (const series of chart.series) {
                if (!series.userOptions?.seriesType) {
                    continue;
                }
                series.data.forEach((city: any) => {
                    const label = city.dataLabel || null;
                    if (!label) {
                        return;
                    }
                    const coords = {
                        x: label.x,
                        y: label.y,
                        width: label.width,
                        height: label.height,
                    };

                    if (label.x < 0) {
                        // Too far left
                        coords.x = 0;
                    } else if (chart.plotWidth < (label.x + label.width)) {
                        // Too far right
                        coords.x = chart.plotWidth - label.width;
                    } else {
                        return;
                    }

                    label.translate(coords.x, coords.y);
                });
            }

            break;

        // case 'icon': {
        //     // Add the Font Awesome icon to the chart
        //     // const container = chart.container.closest('[data-chart-container]');
        //     const xPos = chart.plotBox.x + chart.pane[0].center[0];
        //     const yPos = chart.plotBox.y + chart.pane[0].center[1];
        //
        //     chart.renderer
        //         .text(
        //             `<i class="${chart.userOptions.icon[0]} fa-${chart.userOptions.icon[1]} fa-lg"></i>`,
        //             xPos,
        //             yPos,
        //             true
        //         )
        //         .attr({
        //             intent: 'overlay-data',
        //         })
        //         .css({
        //             color: defaultChartOptions.title.style.color,
        //             // fontSize: chart.pane.size * .15,
        //             transform: 'translate(-50%, 33%)',
        //         })
        //         .add();
        // }
        //     break;

        // case 'personaSocialGraph': {
        //     const rawData = chart.userOptions.rawData;
        //     const numColumns = 6;
        //     const numRows = 6;
        //     const minPersons = 3;
        //     const offsetTop = 30;
        //     const iconStackWidth = numColumns * personIconDimensions.width;
        //
        //     const followerCount = rawData.groupMetrics.twitterStats.medianFollowers;
        //     const followingCount = rawData.groupMetrics.twitterStats.medianFollowing;
        //
        //     // Find the appropriate base 10 value for the larger count
        //     const base10 = (followerCount > followingCount)
        //         ? Number('1e+' + followerCount.toExponential().split('e+')[1])
        //         : Number('1e+' + followerCount.toExponential().split('e+')[1]);
        //     const largerCount = Math.max(followerCount, followingCount);
        //
        //     // Determine count value for each "person" icon
        //     const personValue = (Math.round(largerCount / base10) * base10) / (numRows * numColumns);
        //     const followerPeople = Math.round(followerCount / personValue);
        //     const followingPeople = (Math.round(followingCount / personValue)) > 1
        //         ? Math.round(followingCount / personValue)
        //         : minPersons;
        //     const consumerPeople = 1; // Hard-coded per PersonaBuilder 2.x
        //
        //     const arrow = [
        //         'M', 17.77, -0,
        //         'C', 12.74, -0, 7.38, 2.05, 0, 6.01,
        //         'L', 0.95, 7.77,
        //         'C', 8.23, 3.87, 13.29, 2, 17.77, 2,
        //         'C', 21.8, 2, 25.50, 3.49, 30.17, 6.6,
        //         'L', 26.29, 9.49,
        //         'L', 39.52, 11.2,
        //         'L', 31.52, 0.53,
        //         'L', 30.98, 4.74,
        //         'C', 26.26, 1.63, 22.20, -0, 17.77, -0,
        //         'z'
        //     ];
        //     const arrowWidth = 39;
        //
        //     drawPeopleIconStack(chart, {
        //         rows: numRows,
        //         columns: numColumns,
        //         left: 20,
        //         top: offsetTop,
        //         count: Utils.formatValue(followerPeople, 'separated', 0),
        //         total: Math.round(followerCount),
        //         label: 'Median Followers',
        //         color: BaseColors.purple['100'], // purple
        //     });
        //
        //     chart.renderer
        //         .path(arrow)
        //         .attr({
        //             fill: 'black',
        //             translateX: (chart.plotWidth * .33) - (arrowWidth / 2) - 5,
        //             translateY: offsetTop + 100,
        //             intent: 'overlay-data',
        //         })
        //         .add();
        //
        //     drawPeopleIconStack(chart, {
        //         rows: numRows,
        //         columns: numColumns,
        //         left: (chart.plotWidth / 2) - (iconStackWidth / 2),
        //         top: offsetTop,
        //         count: Math.round(consumerPeople),
        //         includeTotal: false,
        //         // total: Utils.formatValue(consumerPeople, 'separated', 0),
        //         label: 'Typical Individual',
        //         color: BaseColors.blue['75'], // blue-75
        //     });
        //
        //     chart.renderer
        //         .path(arrow)
        //         .attr({
        //             fill: 'black',
        //             translateX: (chart.plotWidth * .66) - (arrowWidth / 2) + 10,
        //             translateY: offsetTop + 100,
        //             intent: 'overlay-data',
        //         })
        //         .add();
        //
        //     drawPeopleIconStack(chart, {
        //         rows: numRows,
        //         columns: numColumns,
        //         left: chart.plotWidth - iconStackWidth - 20,
        //         top: offsetTop,
        //         count: Math.round(followingPeople),
        //         // count: following_people,
        //         total: Utils.formatValue(followingCount, 'separated', 0),
        //         label: 'Median Following',
        //         color: BaseColors.teal['100'],
        //     });
        // }
        //     break;

        // case 'politicalPartyAffiliation': {
        //     const numColumns = 6;
        //     const numRows = 8;
        //     const offsetTop = 0;
        //     const iconStackWidth = numColumns * personIconDimensions.width;
        //     const stackLeft = [
        //         0,
        //         (chart.plotWidth / 2) - (iconStackWidth / 2),
        //         chart.plotWidth - iconStackWidth,
        //     ];
        //     const labelPos = [
        //         {x: 0, transform: null},
        //         {x: (chart.plotWidth / 2), transform: 'translateX(-50%)'},
        //         {x: chart.plotWidth, transform: 'translateX(-100%)'},
        //     ];
        //     const affiliationColor = {
        //         D: BaseColors.blue['75'],
        //         U: BaseColors.purple['75'],
        //         R: BaseColors.red['75'],
        //     }
        //     const baselineBarPos = chart.plotHeight - 25,
        //         baselineLabelPos = chart.plotHeight;
        //
        //     let index = 0,
        //         barLeft = 0;
        //     for (const affiliation of chart.userOptions.rawData) {
        //         drawPeopleIconStack(chart, {
        //             rows: numRows,
        //             columns: numColumns,
        //             left: stackLeft[index],
        //             top: offsetTop,
        //             count: Math.round(Math.min(affiliation.overlayRatio, 96) / 2), // Cap at 96 per requirements
        //             label: `${affiliation.value}: <strong>${Utils.formatValue(affiliation.overlayRatio, 'percent', 1)}</strong>`,
        //             color: affiliationColor[affiliation.originalValue],
        //         });
        //
        //         // Baseline bar
        //         let barWidth = chart.plotWidth * (affiliation.baselineRatio / 100)
        //         chart.renderer
        //             .rect({
        //                 x: barLeft,
        //                 y: baselineBarPos,
        //                 width: barWidth,
        //                 height: 8,
        //             })
        //             .attr({
        //                 fill: affiliationColor[affiliation.originalValue],
        //                 intent: 'overlay-data',
        //             })
        //             .add();
        //
        //         // Baseline label
        //         let textLabel = `${affiliation.originalValue}: ${Utils.formatValue(affiliation.baselineRatio, 'percent', 1)}`;
        //         if (index === 0) {
        //             textLabel = `Baseline: ${textLabel}`;
        //         }
        //         chart.renderer
        //             .text(
        //                 textLabel,
        //                 labelPos[index].x,
        //                 baselineLabelPos,
        //                 true
        //             )
        //             .attr({
        //                 intent: 'overlay-data',
        //             })
        //             .css({
        //                 color: defaultChartOptions.title.style.color,
        //                 transform: labelPos[index].transform,
        //             })
        //             .add();
        //         barLeft += barWidth + 1; // 1px buffer between elements
        //
        //         ++index;
        //     }
        // }
        //     break;

        // case 'topAccounts':
        //     const zIndex = 1;
        //     const elementPadding = 10;
        //     const topAccountsData = chart.series[3].userOptions.topAccounts;
        //     const barSize = chart.series[0].data[0].shapeArgs.width;
        //
        //     let plotArea = chart.container.querySelector('.highcharts-plot-border'),
        //         xOffset = chart.container.offsetWidth - parseInt(plotArea.getAttribute('width'));
        //     chart.series[2].data.forEach((point, pointIndex) => {
        //         const accountData = topAccountsData[pointIndex];
        //         if (!accountData.accounts?.length) {
        //             // Nothing to render!
        //             return false;
        //         }
        //
        //         let itemOffset = 0;
        //         accountData.accounts.forEach((account, accountIndex) => {
        //             if (accountIndex > 2) return;
        //             let xPos = xOffset + itemOffset;
        //             let yPos = chart.container.offsetHeight - point.shapeArgs.x;
        //
        //             // Rank & circle
        //             const rankText = chart.renderer
        //                 .text(
        //                     `${accountIndex + 1}`,
        //                     xPos,
        //                     yPos,
        //                     true
        //                 )
        //                 .attr({
        //                     class: 'rank-label',
        //                     zIndex,
        //                     intent: 'overlay-data',
        //                 })
        //                 .css({
        //                     lineHeight: `${barSize}px`,
        //                     minHeight: `${barSize}px`,
        //                     minWidth: `${barSize}px`,
        //                 })
        //                 .add();
        //             let elementOffset = rankText.getBBox().width + (elementPadding / 2);
        //             itemOffset += elementOffset;
        //
        //             // Account image & label
        //             let accountName = account.dataType === 'twitterFollow' ? account.item : account.displayName,
        //                 accountImage = account.hasOwnProperty('imageUrl') ? `<img src="${account.imageUrl}" width="${barSize}" height="${barSize}" onerror="${Utils.imageFallback('twitter')}"/>` : '';
        //             if (account.dataType === 'twitterFollow') {
        //                 accountName = `<a title="${account.displayName}" target="_new" href="https://twitter.com/${account.item}">${accountName}</a>`;
        //                 accountImage = accountImage.length ?
        //                     `<a title="${account.displayName}" target="_new" href="https://twitter.com/${account.item}">${accountImage}</a>` :
        //                     accountImage;
        //             }
        //             const accountLabel = chart.renderer
        //                 .text(
        //                     `${accountImage} ${accountName}`,
        //                     xPos + elementOffset,
        //                     yPos,
        //                     true
        //                 )
        //                 .attr({
        //                     zIndex,
        //                     intent: 'overlay-data',
        //                 })
        //                 .css({
        //                     lineHeight: `${barSize}px`,
        //                 })
        //                 .add();
        //             itemOffset += accountLabel.getBBox().width + elementPadding;
        //         });
        //
        //     });
        //     break;

        // case 'twitterFollowFrequency':
        //     const labelPadding = 30;
        //     const twitterStats = chart.userOptions.rawData.twitterStats;
        //
        //     // Center the legend vertically
        //     const chartHeight = chart.plotBox.height - chart.options.chart.marginBottom;
        //     chart.legend.destroy();
        //     chart.legend.options.y = chart.plotBox.y + (chartHeight / 2);
        //     chart.legend.render();
        //
        //     chart.renderer
        //         .text(
        //             `
        //                         Average Followers: <strong>${Utils.formatValue(twitterStats.avgFollowers, 'separated', 0)}</strong><br>
        //                         Average Following: <strong>${Utils.formatValue(twitterStats.avgFollowing, 'separated', 0)}</strong>
        //                     `,
        //             (chart.renderTo.clientWidth * .25) + labelPadding,
        //             chart.renderTo.clientHeight - labelPadding,
        //             true
        //         )
        //         .attr({
        //             intent: 'overlay-data',
        //         })
        //         .css({
        //             color: defaultChartOptions.title.style.color,
        //             transform: 'translateX(-50%)'
        //         })
        //         .add();
        //
        //     chart.renderer
        //         .text(
        //             `
        //                         Max Followers: <strong>${Utils.formatValue(twitterStats.maxFollowers, 'separated', 0)}</strong><br>
        //                         Max Following: <strong>${Utils.formatValue(twitterStats.maxFollowing, 'separated', 0)}</strong>
        //                     `,
        //             (chart.renderTo.clientWidth * .75) - labelPadding,
        //             chart.renderTo.clientHeight - labelPadding,
        //             true
        //         )
        //         .attr({
        //             intent: 'overlay-data',
        //         })
        //         .css({
        //             color: defaultChartOptions.title.style.color,
        //             transform: 'translateX(-50%)'
        //         })
        //         .add();
        //     break;
    }
}

/**
 * Sort and filter comparison data (multi-array)
 *
 * @param sourceData
 * @param options
 */
export const comparisonDataSort = (sourceData: any[], options: ComparisonDataSortOptions) => {
    const {filterGroup, filterSettings, matchProperty, sortGroup} = options;
    let series: any[] = [];
    let seriesCategories: any[] = [];

    const numericSortGroup = Utils.isNumeric(sortGroup) ? parseInt(sortGroup) : -1;
    if (numericSortGroup > 0) {
        // Move the specified group to the FRONT of the stack so that it can be used as the sorting source when needed
        sourceData.unshift(sourceData.splice(numericSortGroup, 1)[0]);
    }

    let chartElementCount = 0;
    let i = 0;
    for (let {personaIndex, chartSourceData} of sourceData) {
        if (!chartSourceData || !filterGroup || !sortGroup) {
            break;
        }

        // Add the original index position of the items
        chartSourceData = Utils.sortByProperty(chartSourceData, filterGroup, 'desc')
            .map((item: any, sortIndex) => Object.assign(item, {originalPosition: sortIndex + 1}));

        switch (sortGroup) {
            case 'independent':
                // No action needed, we already sorted the data
                break;

            default: // Numeric sort group
                if (i !== 0) {
                    // Sort by the values in the opposing group
                    let remappedChartSourceData: any[] = [];
                    for (let sourceDataItem of sourceData[0].chartSourceData) {
                        // Find the matching item in this series
                        remappedChartSourceData.push(chartSourceData.find((item: any) => {
                            const itemMatch = item[matchProperty],
                                sourceMatch = sourceDataItem[matchProperty];
                            if (typeof itemMatch === 'object' && itemMatch !== null) {
                                // Use the ID property of the match
                                return itemMatch.id === sourceMatch.id
                            } else {
                                return itemMatch === sourceMatch;
                            }
                        }));
                    }
                    chartSourceData = remappedChartSourceData;
                }
                break;
        }

        seriesCategories.push({
            personaIndex,
            labels: chartSourceData.map((category: any) => {
                return {
                    category,
                    originalPosition: category?.originalPosition || '',
                }
            }),
        });

        let seriesData: any = {
            type: 'bar',
            color: reportColors.comparison[personaIndex],
            dataLabels: [{
                align: 'left',
                format: '{point.rank}'
            }],
            formatter: value => {
                return `${value.toFixed(2)}`;
            },
        };
        seriesData.data = chartSourceData.map((item: any) => {
            let rank;
            if (item) {
                rank = item.originalPosition ? `#${item.originalPosition}` : '';
            }

            return {
                rank,
                y: item ? item[filterGroup] || 0 : 0,
            }
        });

        if (filterSettings.count) {
            seriesData.data.splice(filterSettings.count, seriesData.data.length - filterSettings.count);
        }

        seriesData.personaIndex = personaIndex;
        series.push(seriesData);
        if (chartElementCount < seriesData.data.length) {
            chartElementCount = seriesData.data.length;
        }
        ++i;
    }

    // Reorder the series by original indexing
    seriesCategories = Utils.sortByProperty(seriesCategories, 'personaIndex', 'asc');
    series = Utils.sortByProperty(series, 'personaIndex', 'asc');

    return {
        chartElementCount,
        series,
        seriesCategories,
    };
}

// Find the max indexScore amongs ONLY the top bins (8, 9, 10)
export const decileTopIndexScore = (dataElement: any): number => {
    return Math.max(
        ...dataElement.statsDetail
            .filter((detail: any) => detail.binId >= 8)
            .map((bin: any) => bin.indexScore)
    );
}

export const decileWeightedIndexScore = (dataElement: any): number => {
    const bins = dataElement.statsDetail
        .filter((detail: any) => detail.binId >= 8);

    // Weighted algorithm - DEPRECATED VIA DATAPROD-2366
    // return bins.reduce((accumulator: number, detail: any) => accumulator + (detail.binId * detail.indexScore), 0);

    // Simply pick the highest indexScore among the targeted bins
    return Math.max(...bins.map((detail: any) => detail.indexScore));
}

export const filterByThreshold = (data: any[], threshold: number, type: string) => {
    if (threshold === 0) {
        return data;
    }

    return data.filter((element: any) => {
        // console.info(
        //     `${meetsThreshold ? '🟢' : '🛑'} Element "${element.shortDescription}" @ ${threshold}% threshold\r\t
        //     BEST BIN: ${element.relatedOverlayNumberOfIndividuals}\r\t
        //     THRESHOLD: ${elementThreshold}\r\t
        //     TOTAL: ${totalIndividuals}\r\t`,
        // );
        switch (type) {
            case 'count':
                return element.relatedOverlayNumberOfIndividuals >= threshold;

            case 'percent':
                const totalIndividuals = element.statsDetail
                    .reduce((accumulator: number, detail: any) => accumulator + detail.overlayNumberOfIndividuals, 0);
                let elementThreshold = Math.round(totalIndividuals * (threshold / 100));

                return element.relatedOverlayNumberOfIndividuals >= elementThreshold;
        }
    });
}

export const formatOutlineSeries = (outlineData) => {
    return {
        name: 'State Lines',
        type: 'mapline',
        data: Highcharts['geojson'](outlineData),
        // data: Highcharts['topojson'](outlineData),
        lineWidth: 0.5,
        color: BaseColors.gray['75'],
        enableMouseTracking: false,
        nullInteraction: false,
        showInLegend: false,
        // mapView: {
        //     projection: {
        //         name: 'LambertConformalConic',
        //         parallels: [33, 45],
        //         rotation: [96],
        //     }
        // }
    }
}

export const hasBinnedData = (element) => {
    return element.statsDetail?.every((detail: any) => !detail.hasOwnProperty('forcedBin'))
        && element.statsDetail?.every((detail: any) => detail.hasOwnProperty('binId'));
}

// export const hasTweets = (topic) => {
//     return topic.twitter?.topByCount?.reduce((total, account) => {
//         return total + ((account.tweetCount > 0 || false) ? 1 : 0);
//     }, 0) || false;
// }

/**
 * Prepare chart data for display
 */
export const prepareChartData = (options: any) => {
    options = Object.assign({
        asyncData: {},
        chartId: null,
        filterGroup: '',
        filterSettings: {},
        Highcharts: {},
        insights: {},
        params: {},
        printMode: false,
        returnData: true,
        settings: {},
        sortGroup: {},
    }, options);
    options.params.chartLabels = Object.assign({
        baseline: 'Baseline',
        overlay: 'Customer File',
    }, options.params.chartLabels || {});

    const {
        asyncData,
        chartId,
        filterGroup,
        filterSettings,
        Highcharts,
        // insights,
        params,
        printMode,
        reportData,
        returnData,
        settings,
        sortGroup,
    } = options;

    let chartData: any = {};
    let chartSourceData;
    // console.info(`📊 Preparing chart data "${chartId}"...`);

    switch (chartId) {
        case 'main_summary_file_analysis': {
            const totalRecordCount = reportData.file.inputRecords;
            const matchedRecordCount = Math.round(totalRecordCount * reportData.file.dataOverlayRate);
            const unmatchedRecordCount = totalRecordCount - matchedRecordCount;
            const series = [
                {
                    name: 'Record Count',
                    data: [
                        {
                            color: reportColors.donut['standard'][0],
                            name: `Matched<br>${matchedRecordCount.toLocaleString()}`,
                            y: matchedRecordCount,
                        },
                        {
                            color: reportColors.donut['standard'][1],
                            name: `Unmatched<br>${unmatchedRecordCount.toLocaleString()}`,
                            y: unmatchedRecordCount,
                        },
                    ]
                }
            ];

            chartData = {
                id: chartId,
                chart: {
                    type: 'pie',
                },
                legend: false,
                plotOptions: Highcharts.merge({}, chartPlotOptions.donut, {
                    pie: {
                        dataLabels: {
                            distance: 10,
                            enabled: true,
                            style: {
                                fontSize: '18px',
                                textOverflow: 'visible',
                            },
                        },
                    },
                }),
                title: {
                    text: `Customer File Analysis`,
                },
                subtitle: {
                    style: {
                        fontSize: '18px',
                    },
                    text: `Input Records: <strong>${totalRecordCount.toLocaleString()}</strong>`,
                    useHTML: true,
                },
                xAxis: {
                    categories: [`Input Records: <strong>${Number(totalRecordCount).toLocaleString()}</strong>`],
                    gridLineWidth: 0,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            text: `Record Count`,
                        },
                    },
                ],
                series,
                tooltip: {
                    enabled: false,
                },
            };
        }
            break;

        case 'main_summary_match_rate': {
            const series = [
                {
                    name: 'Match Rate',
                    // color: reportColors.barStacked[0],
                    data: [reportData.file.dataOverlayRate * 100],
                    formatter: value => Utils.formatValue(value, 'percent'),
                },
            ];

            chartData = {
                id: chartId,
                chart: {
                    type: 'gauge',
                    height: '80%',
                },
                legend: false,
                title: {
                    text: 'Match Rate',
                },
                pane: {
                    startAngle: -90,
                    endAngle: 89.9,
                    background: null,
                    center: ['50%', '75%'],
                    size: '110%'
                },
                plotOptions: Highcharts.merge({}, chartPlotOptions.barGroup, {
                    gauge: {
                        dataLabels: {
                            format: '{y}%',
                            borderWidth: 0,
                            style: {
                                fontWeight: 'bold',
                                fontSize: '18px',
                            }
                        }
                    },
                }),
                yAxis: {
                    min: 0,
                    max: 100,
                    tickPixelInterval: 72,
                    tickPosition: 'inside',
                    tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
                    tickLength: 20,
                    tickWidth: 2,
                    minorTickInterval: null,
                    labels: {
                        distance: 20,
                    },
                    lineWidth: 0,
                    plotBands: [{
                        from: 0,
                        to: 40,
                        color: reportColors.gauge.low,
                        thickness: 20
                    }, {
                        from: 40,
                        to: 60,
                        color: reportColors.gauge.mid,
                        thickness: 20
                    }, {
                        from: 60,
                        to: 100,
                        color: reportColors.gauge.high,
                        thickness: 20
                    }]
                },
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', false),
                },
            };
        }
            break;

        //**** Pattern-based chart ID matches follow ****//

        case (chartId.match(dynamicElementPattern) || {}).input: {
            const [, tabSlug, sectionSlug, chartType, dataElementId] = chartId.match(dynamicElementPattern);
            const pageData = reportData.pages.find((page: any) => Utils.slug(page.pageDescription) === tabSlug);
            if (!pageData) {
                return false;
            }
            const sectionData = pageData.categories.find((category: any) => Utils.slug(category.categoryDescription) === sectionSlug);
            if (!sectionData) {
                return false;
            }
            const elementDefinitionData = sectionData.dataElements.find((element: any) => element.dataElementDisplayId === dataElementId);
            if (!elementDefinitionData) {
                return false;
            }
            const element = reportData.report.stats.find((element: any) => element.dataElementId === dataElementId);
            if (!element) {
                console.error(`🛑 No data element found for "${dataElementId}"`);
                return false;
            }
            chartSourceData = element.statsDetail;

            // let hasLinearityLiftData = false;
            let subtitle: any = {};
            if (element.hasOwnProperty('linearityLiftScore')) {
                // hasLinearityLiftData = true;
                subtitle = {
                    text: `${linearityLiftScoreDescriptor}: ${Utils.formatValue(element.linearityLiftScore, 'decimal', 4)}`,
                    style: {
                        color: BaseColors.orange[75],
                        fontSize: '10pt',
                    },
                };
            }
            if (hasBinnedData(element)) {
                // Non-decile bins
                chartSourceData = Utils.sortByProperty(chartSourceData, 'binId', 'desc');
            }

            // Backfill undefined index values with 0 for chart plotting consistency
            chartSourceData = chartSourceData.map((detail: any) => {
                if (detail.indexScore === undefined || detail.indexScore === null) {
                    detail.indexScore = 0;
                }
                return detail;
            });

            const rawData = clone(chartSourceData);
            if (chartType !== 'map') {
                chartSourceData = chartSourceData.map((detail: any) => {
                    return {
                        label: detail.description,
                        index: detail.indexScore,
                        baselineValue: detail.baselinePercent,
                        overlayValue: detail.overlayPercent,
                        // longDescription: detail.longDescription,
                        // shortDescription: detail.shortDescription,
                    }
                });
            }

            switch (chartType) {
                case 'column':
                default:
                    chartData = {
                        chart: {
                            type: 'column',
                        },
                        subtitle,
                        plotOptions: chartPlotOptions.bar,
                        xAxis: {
                            categories: chartSourceData.map((item: any) => item.label),
                        },
                        yAxis: chartAxisOptions.barIndexGroupPercent,
                        series: chartDataToSeries('barIndex', chartSourceData, {
                            chartLabels: params.chartLabels,
                        }),
                        tooltip: {
                            // followPointer: true,
                            formatter: tooltipFormatter('flex', {
                                includeHeader: true,
                                footer: chartSourceData.map((detail: any) => {
                                    return {
                                        x: detail.label,
                                        value: element.longDescription,
                                    }
                                }),
                            }),
                            outside: true,
                            shared: true,
                            useHTML: true,
                        },
                    };
                    break;

                case 'donut':
                    chartData = {
                        chart: {
                            type: 'pie',
                        },
                        plotOptions: chartPlotOptions.donut,
                        series: [
                            {
                                name: params.chartLabels.overlay,
                                size: '80%',
                                data: chartDataToSeries('donut', chartSourceData, {property: 'overlayValue'})
                                    .map((item, index) => Object.assign(item, {color: reportColors.donut.overlayRatio[index]})),
                            },
                            {
                                name: params.chartLabels.baseline,
                                size: '100%',
                                innerSize: '85%',
                                data: chartDataToSeries('donut', chartSourceData, {property: 'baselineValue'})
                                    .map((item, index) => Object.assign(item, {color: reportColors.donut.baselineRatio[index]})),
                                showInLegend: false
                            }
                        ],
                        tooltip: {
                            // followPointer: true,
                            formatter: tooltipFormatter('piePercent'),
                            outside: true,
                            shared: true,
                            useHTML: true,
                        },
                    };
                    break;

                case 'map': {
                    const localSortGroup = sortGroup || 'indexScore';
                    const baseOutlineKey = `${sectionSlug}_baseOutlines`;
                    const mapDataKey = `${sectionSlug}_mapData`;
                    if (!returnData) {
                        return {
                            asyncData: async () => {
                                try {
                                    const response = await axios.get(`/assets/topo-json/${sectionSlug}.topo.json`);
                                    let topoData = clone(response.data);
                                    let seriesData = topoData.objects.collection.geometries;

                                    // Handle duplicate shape IDs
                                    let shapeIds: any = {};
                                    for (let topoItem of seriesData) {
                                        const uniqueId = `${topoItem.properties[topoJsonIdentityProperty]}`;
                                        if (shapeIds.hasOwnProperty(uniqueId)) {
                                            // Duplicate found
                                            ++shapeIds[uniqueId];
                                            topoItem.properties[topoJsonIdentityProperty] = `${uniqueId}${topoJsonIdentityDuplicateIndicator}${shapeIds[uniqueId]}`;
                                        } else {
                                            shapeIds[uniqueId] = 1;
                                        }
                                    }
                                    Highcharts.maps[mapDataKey] = topoData;

                                    // Load standard base outlines...
                                    Highcharts.maps[baseOutlineKey] = await baseOutlines();
                                } catch (e) {
                                    // Use the standard base outlines as the map data
                                    Highcharts.maps[mapDataKey] = await baseOutlines(true, true);
                                }
                            }
                        }
                    }
                    const mapData = Highcharts.maps[mapDataKey];
                    let baseOutlineData = Highcharts.maps[baseOutlineKey] || false;
                    const useCustomShapes = Highcharts.maps.hasOwnProperty(baseOutlineKey);

                    let dataProperty: string = '';
                    let seriesData: any = [];

                    if (useCustomShapes) {
                        // dataProperty = 'code';
                        dataProperty = topoJsonIdentityProperty;
                        seriesData = mapData.objects.collection.geometries;
                        const duplicateSeriesDataItems = seriesData.filter((mapItem: any) => (mapItem.properties[dataProperty]?.indexOf(topoJsonIdentityDuplicateIndicator) || -1) > -1);
                        for (const dupe of duplicateSeriesDataItems) {
                            const dupeId = `${dupe.properties[dataProperty]}`;
                            const uniqueId = dupeId.split(topoJsonIdentityDuplicateIndicator)[0];

                            // Copy the matching stats detail data to the new ID
                            const dataMatch = chartSourceData.find((item: any) => item.codedValue?.toString() === uniqueId);
                            if (dataMatch) {
                                let clonedData = clone(dataMatch);
                                clonedData.codedValue = dupeId;
                                chartSourceData.push(clonedData);
                            }
                        }
                    } else {
                        // Assume we're using the State data provided by Highcharts...
                        baseOutlineData = formatOutlineSeries(mapData);
                        dataProperty = 'hc-a2';
                        seriesData = baseOutlineData.data;
                    }

                    const data = chartSourceData
                        .map((item: any) => {
                            const code = item.codedValue;
                            const dataMatch = seriesData.find((feature: any) => feature.properties[dataProperty]?.toString() === `${code}`);
                            if (!dataMatch) {
                                return false;
                            }

                            return Object.assign({}, item, {
                                code,
                                name: item.description,
                                value: item[localSortGroup] || item.indexScore,
                            });
                        })
                        .filter((item: any) => item !== false)
                    ;

                    const maxDataValue = Math.min(100, Math.max(...data.map((item: any) => item.value))); // Cap the max value at 100, in case of weird outliers
                    // // const maxGroupRatio = Math.min(100, Math.max(...chartSourceData.map((item: any) => item.groupRatio))); // Cap the max value at 100, in case of weird outliers
                    const axisBreakpoint = localSortGroup === 'indexScore' ? 1 : maxDataValue / 2;
                    const lowData = data.filter((item: any) => item.value < axisBreakpoint);
                    const highData = data.filter((item: any) => item.value >= axisBreakpoint);

                    // Enable data labels for the top 5 and bottom 5 items
                    const sortedValues = Utils.sortByProperty(data, localSortGroup, 'desc');
                    sortedValues.slice(0, 5).forEach((item: any) => {
                        data.find((dataItem: any) => dataItem.code === item.code).dataLabels = {
                            range: 'high',
                            enabled: true
                        };
                    });
                    sortedValues.reverse().slice(0, 5).forEach((item: any) => {
                        data.find((dataItem: any) => dataItem.code === item.code).dataLabels = {
                            range: 'low',
                            enabled: true
                        };
                    });

                    let borderColor = BaseColors.gray['75'];
                    let colorAxis: any[] = [
                        {
                            // Low range
                            endOnTick: false,
                            startOnTick: false,
                            min: 0,
                            max: axisBreakpoint,
                            ceiling: axisBreakpoint,
                            minColor: null,
                            maxColor: null,
                            stops: null,
                        },
                        {
                            // High range
                            endOnTick: false,
                            startOnTick: false,
                            min: axisBreakpoint,
                            max: maxDataValue,
                            ceiling: maxDataValue,
                            minColor: null,
                            maxColor: null,
                            stops: null,
                        },
                    ];
                    let dataLabels;
                    let series;
                    let seriesLabel;
                    switch (localSortGroup) {
                        case 'overlayPercent':
                            borderColor = 'white';
                            colorAxis[0] = Highcharts.merge(colorAxis[0], {
                                stops: [
                                    [0, heatmapStops[0][1]],
                                    [0.5, heatmapStops[1][1]],
                                    [1, heatmapStops[2][1]],
                                ],
                            });
                            colorAxis[1] = Highcharts.merge(colorAxis[1], {
                                stops: [
                                    [0, heatmapStops[3][1]],
                                    [0.5, heatmapStops[4][1]],
                                    [1, heatmapStops[5][1]],
                                ],
                            });
                            dataLabels = Highcharts.merge(chartDataLabelOptions.map, {
                                format: '{point.options.name}: {point.value:.2f}%',
                            });
                            seriesLabel = ['% Low', '% High'];
                            break;

                        case 'indexScore':
                        default:
                            colorAxis[0] = Object.assign({}, colorAxis[0], {
                                minColor: reportColors.axis.low.min,
                                maxColor: reportColors.axis.low.max,
                            });
                            colorAxis[1] = Object.assign({}, colorAxis[1], {
                                minColor: reportColors.axis.high.min,
                                maxColor: reportColors.axis.high.max,
                            });
                            dataLabels = Highcharts.merge(chartDataLabelOptions.map, {
                                format: '{point.options.name}: {point.value:.2f}x',
                            });
                            seriesLabel = ['Index Low', 'Index High'];
                            break;
                    }

                    const chartStateSettings = localSortGroup === 'overlayPercent' ?
                        reportColors.heatmap.states :
                        reportColors.choropleth.states;

                    series = [
                        {
                            name: seriesLabel[0],
                            seriesType: 'low',
                            type: 'map',
                            joinBy: [dataProperty, 'code'],
                            borderWidth: 0.5,
                            borderColor,
                            states: chartStateSettings,
                            shadow: false,
                            mapData,
                            data: lowData,
                            dataLabels,
                            colorAxis: 0,
                        },
                        {
                            name: seriesLabel[1],
                            seriesType: 'high',
                            type: 'map',
                            joinBy: [dataProperty, 'code'],
                            borderWidth: 0.5,
                            borderColor,
                            states: chartStateSettings,
                            shadow: false,
                            mapData,
                            data: highData,
                            dataLabels,
                            colorAxis: 1,
                        },
                    ];
                    if (useCustomShapes) {
                        series.push(baseOutlineData);
                    } else {
                        series.unshift(baseOutlineData);
                    }

                    chartData = {
                        constructorType: 'mapChart',
                        rawData: chartSourceData,
                        sortGroup: localSortGroup,

                        title: {text: undefined},
                        chart: {
                            animation: false,
                            events: {
                                load: chart => {
                                    chartOverlayCallback(chart.target, 'geographicReport');
                                },
                                redraw: (chart: any) => {
                                    chartOverlayCallback(chart.target, 'geographicReport');
                                },
                            },
                            height: '80%',
                        },
                        mapView: {
                            insets: usFocusTerritoryInsets,
                            projection: {
                                name: 'LambertConformalConic', // Best option for US state maps
                                parallels: [33, 45],
                                rotation: [96],
                            }
                        },
                        series,
                        plotOptions: {
                            map: {
                                allAreas: false,
                                nullColor: 'rgba(0, 0, 0, 0)',
                            }
                        },
                        colorAxis,
                        tooltip: {
                            formatter: tooltipFormatter('map'),
                        },
                    };
                }
            }

            chartData.dataType = element.dataType || null;
            chartData.linearityLiftScore = element.linearityLiftScore || null;
            chartData.elementId = element.dataElementId;
            chartData.elementName = element.shortDescription;
            chartData.rawData = rawData;
            if (element.hasOwnProperty('dataElementComposition')) {
                chartData.elementIds = element.dataElementComposition;
            }
            if (!chartData.hasOwnProperty('title')) {
                chartData.title = {text: chartData.elementName};
            }
        }
            break;

        case (chartId.match(dynamicSummaryPattern) || {}).input: {
            if (Utils.isEmptyObject(settings)) {
                return false;
            }

            const [, pageSlug, sectionSlug] = chartId.match(dynamicSummaryPattern);
            const pageData = reportData.pages.find((page: any) => Utils.slug(page.pageDescription) === pageSlug);
            if (!pageData) {
                return false;
            }
            const sectionData = pageData.categories.find((category: any) => Utils.slug(category.categoryDescription) === sectionSlug);
            if (!sectionData) {
                return false;
            }
            const dataElementIds = sectionData.dataElements.map((dataElement: any) => dataElement.dataElementDisplayId);
            let rawData = reportData.report.stats
                .filter((element: any) => dataElementIds.includes(element.dataElementId) && element.characteristicScore > 0);
            const originalCharacteristicCount = +(rawData.length);

            // Apply filters
            if (settings['filterDataElementType.index']?.value) {
                rawData = rawData.filter((element: any) => element.dataElementType !== dataElementTypes.index);
            }
            if (settings['excludedDataElements.summary']?.value?.length > 0) {
                rawData = rawData.filter((element: any) => !settings['excludedDataElements.summary'].value.includes(element.dataElementId));
            }
            rawData = filterByThreshold(
                rawData,
                settings.thresholdPercent.value,
                'percent'
            );
            rawData = filterByThreshold(rawData, settings.thresholdCount.value, 'count');
            if (!rawData.length) {
                return false;
            }

            const filterApplied = originalCharacteristicCount !== rawData.length;

            // Backfill undefined index values with 0 for chart plotting consistency
            rawData = rawData.map((detail: any) => {
                if (detail.indexScore === undefined || detail.indexScore === null) {
                    detail.indexScore = 0;
                }
                if (detail.medianValue === undefined || detail.medianValue === null) {
                    detail.medianValue = 0;
                }
                // if (detail.overlayZScore === undefined || detail.overlayZScore === null) {
                //     detail.overlayZScore = 0;
                // }
                // if (detail.topBinIndexScore === undefined || detail.topBinIndexScore === null) {
                //     detail.topBinIndexScore = 0;
                // }

                return detail;
            });

            chartSourceData = Utils.sortByProperty(rawData, 'characteristicScore', 'desc').slice(0, 25);

            // Apply user-specified sort to the dataset
            chartSourceData = Utils.sortByProperty(chartSourceData, sortGroup, 'desc');

            const numberOfIndividualsData = chartSourceData.map((element: any) => {
                const match = element.statsDetail.find((detail: any) => detail.indexScore === element.displayIndexScore);
                if (!match) {
                    console.warn(
                        `Coverage missing: ${element.dataElementId} ${element.shortDescription}\r\nMAX INDEX: ${element.maxIndexScore}\r\nBIN INDICES: ${element.statsDetail.map((detail: any) => `${detail.binId}: ${detail.indexScore}`).join('; ')}`,
                        element
                    );
                }

                return match?.overlayNumberOfIndividuals || 0;
            })

            // Chart sizing - adjust as needed
            const chartHeight = `${(PLOT_WIDTH_BAR_STACKED * chartSourceData.length * 2) + HEADER_SIZE_BAR_STACKED}`;
            const series = [
                {
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map((element: any) => element.displayIndexScore),
                    name: 'Relative Index',
                    type: 'bar',
                    yAxis: 1,
                    zones: reportColors.zone.relativeIndex,
                },
                {
                    color: reportColors.barIndex.count,
                    data: numberOfIndividualsData,
                    formatter: value => Number(value).toLocaleString(),
                    name: 'Number of Individuals',
                    type: 'bar',
                    yAxis: 0,
                },
            ];
            chartData = {
                filterApplied,
                rawData,

                id: chartId,
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    text: false
                },
                plotOptions: Highcharts.merge({}, chartPlotOptions.barGroup, {
                    plotWidth: PLOT_WIDTH_BAR_STACKED,
                    series: {
                        groupPadding: 0.1,
                    },
                }),
                xAxis: {
                    // categories: chartSourceData.map((element: any) => `${element.shortDescription}`),
                    categories: dataElementCategories(chartSourceData),
                    gridLineWidth: 0,
                    labels: {
                        events: {
                            click: dataElementExcludeHandler,
                        },
                        // formatter: (point: any) => {
                        //     return `<div class="d-flex align-items-center">
                        //         <div class="text-right">${point.value}</div>
                        //         <div class="">${dataElementExcludeLink(chartSourceData[point.pos], chartId)}</div>
                        //     </div>`;
                        // },
                        formatter: (point: any) => dataElementCategoryPointFormatter(point, chartSourceData, chartId),
                        useHTML: true,
                    },
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis: [
                    {
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        margin: 0,
                        minorTickInterval: 0,
                        opposite: true,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: 'Relative Index',
                        },
                        type: 'logarithmic',
                        visible: sortGroup === 'characteristicScore',
                    },
                    {
                        labels: {
                            style: {
                                color: reportColors.barIndex.countLabel,
                            },
                        },
                        margin: 0,
                        minorTickInterval: 0,
                        opposite: true,
                        title: {
                            style: {
                                color: reportColors.barIndex.count,
                            },
                            text: 'Number of Individuals',
                        },
                        type: 'logarithmic',
                        visible: sortGroup === 'relatedOverlayNumberOfIndividuals',
                    },
                ].reverse(),
                series,
                tooltip: {
                    // distance: 50,
                    // followPointer: true,
                    formatter: tooltipFormatter('flex', {
                        includeHeader: true,
                        footer: chartSourceData.map((element: any) => {
                            return {
                                x: element.shortDescription,
                                value: element.longDescription,
                            }
                        }),
                    }),
                    outside: true,
                    useHTML: true,
                },
            };
        }
            break;

        case (chartId.match(mainSummaryCharacteristicPattern) || {}).input: {
            let dataElementIds: string[] = [];
            const [, characteristicType] = chartId.match(mainSummaryCharacteristicPattern);
            let characteristicName = characteristicType.split('-').map((word: any) => Utils.ucFirst(word)).join(' ');
            const pageData = reportData.pages.find((page: any) => Utils.slug(page.pageDescription) === characteristicType);
            if (!pageData) {
                return false;
            }
            // if (!dataElementIds.length) {
                characteristicName = pageData.pageDescription;
                for (const categoryPage of pageData.categories) {
                    for (const dataElement of categoryPage.dataElements) {
                        dataElementIds.push(dataElement.dataElementDisplayId);
                    }
                }
            // }

            let rawData = reportData.report.stats.filter((element: any) => dataElementIds.includes(element.dataElementId));
            let originalCharacteristicCount = +(rawData.length);

            // Apply filters
            if (settings['filterDataElementType.index'].value) {
                rawData = rawData.filter((element: any) => element.dataElementType !== dataElementTypes.index);
            }
            if (settings['excludedDataElements.summary']?.value?.length > 0) {
                rawData = rawData.filter((element: any) => !settings['excludedDataElements.summary'].value.includes(element.dataElementId));
            }
            rawData = filterByThreshold(
                rawData,
                settings.thresholdPercent.value,
                'percent'
            );
            rawData = filterByThreshold(rawData, settings.thresholdCount.value, 'count');
            const filterApplied = originalCharacteristicCount !== rawData.length;
            if (!rawData.length) {
                return false;
            }

            // Backfill undefined index values with 0 for chart plotting consistency
            rawData = rawData.map((detail: any) => {
                switch (detail.dataElementType) {
                    case dataElementTypes.numerical:
                        if (detail.indexScore === undefined || detail.indexScore === null) {
                            detail.indexScore = 0;
                        }
                        if (detail.medianValue === undefined || detail.medianValue === null) {
                            detail.medianValue = 0;
                        }
                        if (detail.topBinIndexScore === undefined || detail.topBinIndexScore === null) {
                            detail.topBinIndexScore = 0;
                        }

                        // detail.characteristicScore = detail.topBinIndexScore;
                        break;

                    default:
                        // detail.characteristicScore = detail.maxIndexScore;
                        break;
                }

                return detail;
            });

            // Determine data types
            let dataTypes: string[] = [];
            for (const element of rawData) {
                if (!dataTypes.includes(element.dataElementType)) {
                    dataTypes.push(element.dataElementType);
                }
            }

            let sortProperty: string;
            switch (true) {
                case (dataTypes.includes(dataElementTypes.categorical)):
                default:
                    sortProperty = 'characteristicScore';
                    // do something else
                    break;

                case (dataTypes.includes(dataElementTypes.numerical)):
                    sortProperty = 'linearityLiftScore';
                    // execute
                    break;
            }

            chartSourceData = Utils.sortByProperty(rawData, sortProperty, 'desc')
                .slice(0, 10)
                .map((element: any) => {
                    // Determine the index-related counts
                    const detail = element.statsDetail.find((detail: any) => detail.indexScore === element.displayIndexScore);
                    if (!detail) {
                        return null;
                    }

                    element.relatedOverlayNumberOfIndividuals = detail.overlayNumberOfIndividuals || 0;
                    return element;
                })
                .filter((element: any) => element !== null);

            // let categories = chartSourceData.map((element: any) => {
            //     let categoryLabel = element.shortDescription;
            //     switch (element.dataElementType) {
            //         case dataElementTypes.numerical:
            //         case dataElementTypes.index:
            //             break;
            //
            //         default:
            //             // Include the specific detail descriptor
            //             const detail = element.statsDetail.find((detail: any) => detail.indexScore === element.displayIndexScore);
            //             categoryLabel = `${categoryLabel}:<br><strong>${detail.description}</strong>`;
            //     }
            //
            //     return categoryLabel;
            // });

            // Chart sizing - adjust as needed
            const chartHeight = `${(PLOT_WIDTH_BAR_STACKED * chartSourceData.length * 2) + HEADER_SIZE_BAR_STACKED}`;
            let series: any[] = [
                {
                    // color: reportColors.barIndex.index,
                    data: chartSourceData.map((element: any) => element.displayIndexScore),
                    name: 'Relative Index',
                    // threshold: 1,
                    type: 'bar',
                    yAxis: 1,
                    zones: reportColors.zone.relativeIndex,
                },
                {
                    color: reportColors.barIndex.count,
                    // data: chartSourceData.map((element: any) => element.statsDetail.find((detail: any) => detail.indexScore === element.maxIndexScore).overlayNumberOfIndividuals),
                    // data: chartSourceData.map((element: any) => element.topBinOverlayNumberOfIndividuals),
                    data: chartSourceData.map((element: any) => element.relatedOverlayNumberOfIndividuals),
                    formatter: (value: any) => Number(value).toLocaleString(),
                    name: 'Number of Individuals',
                    type: 'bar',
                    yAxis: 0,
                },
            ];

            let yAxis: any[] = [
                {
                    labels: {
                        style: {
                            color: reportColors.barIndex.indexLabel,
                        },
                    },
                    margin: 0,
                    opposite: true,
                    title: {
                        style: {
                            color: reportColors.barIndex.index,
                        },
                        text: 'Relative Index',
                    },
                    type: 'logarithmic',
                },
                {
                    labels: {
                        style: {
                            color: reportColors.barIndex.countLabel,
                        },
                    },
                    margin: 0,
                    minorTickInterval: 0,
                    opposite: true,
                    title: {
                        style: {
                            color: reportColors.barIndex.count,
                        },
                        text: 'Number of Individuals',
                    },
                    type: 'logarithmic',
                    visible: false,
                },
            ];

            if (sortProperty === 'linearityLiftScore') {
                // Include Linearity & Lift
                series.unshift({
                    color: reportColors.barIndex.linearityLiftScore,
                    data: chartSourceData.map((element: any) => element.linearityLiftScore),
                    name: 'Linearity and Lift',
                    // threshold: 1,
                    type: 'bar',
                    yAxis: 2,
                });

                yAxis = yAxis.map((axisItem: any) => {
                    axisItem.visible = false;
                    return axisItem;
                });
                yAxis.unshift({
                    labels: {
                        style: {
                            color: reportColors.barIndex.linearityLiftScoreLabel,
                        },
                    },
                    margin: 0,
                    minorTickInterval: 0,
                    opposite: true,
                    title: {
                        style: {
                            color: reportColors.barIndex.linearityLiftScore,
                        },
                        text: 'Linearity and Lift Score',
                    },
                    type: 'logarithmic',
                    visible: true,
                });
            }

            yAxis.reverse();

            chartData = {
                filterApplied,
                rawData,

                // icon,
                id: chartId,
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    // text: false,
                    text: `Top 10 ${characteristicName}`,
                },
                plotOptions: Highcharts.merge({}, chartPlotOptions.barGroup, {
                    plotWidth: PLOT_WIDTH_BAR_STACKED,
                    series: {
                        groupPadding: 0.1,
                    },
                }),
                xAxis: {
                    // categories,
                    categories: dataElementCategories(chartSourceData),
                    gridLineWidth: 0,
                    labels: {
                        events: {
                            click: dataElementExcludeHandler,
                        },
                        // formatter: (point: any) => {
                        //     return `<div class="d-flex align-items-center">
                        //         <div class="text-right">${point.value}</div>
                        //         <div class="">${dataElementExcludeLink(chartSourceData[point.pos], chartId)}</div>
                        //     </div>`;
                        // },
                        formatter: (point: any) => dataElementCategoryPointFormatter(point, chartSourceData, chartId),
                        useHTML: true,
                    },
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis,
                series,
                tooltip: {
                    // distance: 50,
                    // followPointer: true,
                    formatter: tooltipFormatter('flex', {
                        includeHeader: true,
                        footer: chartSourceData.map((element: any) => {
                            return {
                                x: element.shortDescription,
                                value: element.longDescription,
                            }
                        }),
                    }),
                    outside: true,
                    useHTML: true,
                },
            };
        }
            break;

        case (chartId.match(mainSummaryCharacteristicsAllPattern) || {}).input: {
            const [, typeFilter, sortDirection] = chartId.match(mainSummaryCharacteristicsAllPattern);
            const scoreProperty = 'characteristicScore';
            const dataTypes = typeFilter.split(',');
            const dataTypePattern = new RegExp(dataTypes.join('|'), 'i');

            // Only consider characteristics with a positive score, so that we don't display null data
            let characteristics = reportData.report.stats.filter((characteristic: any) => {
                    return dataTypePattern.test(characteristic.dataElementType)
                        && characteristic.hasOwnProperty(scoreProperty)
                        && characteristic[scoreProperty] > 0
                });
            let originalCharacteristicCount = +(characteristics.length);

            // Apply filters
            if (settings['filterDataElementType.index']?.value) {
                characteristics = characteristics.filter((element: any) => element.dataElementType !== dataElementTypes.index);
            }
            if (settings['excludedDataElements.summary']?.value?.length > 0) {
                characteristics = characteristics.filter((element: any) => !settings['excludedDataElements.summary'].value.includes(element.dataElementId));
            }
            characteristics = filterByThreshold(
                characteristics,
                settings.thresholdPercent.value,
                'percent'
            );
            characteristics = filterByThreshold(characteristics, settings.thresholdCount.value, 'count');
            const filterApplied = originalCharacteristicCount !== characteristics.length;

            chartSourceData = Utils.sortByProperty(characteristics, scoreProperty, sortDirection)
                .slice(0, sortDirection === 'asc' ? 10 : 25);

            const numberOfIndividualsData = chartSourceData.map((element: any) => {
                const match = element.statsDetail.find((detail: any) => detail.indexScore === element.displayIndexScore);
                if (!match) {
                    console.warn(
                        `Coverage missing: ${element.dataElementId} ${element.shortDescription}\r\nMAX INDEX: ${element.maxIndexScore}\r\nBIN INDICES: ${element.statsDetail.map((detail: any) => `${detail.binId}: ${detail.indexScore}`).join('; ')}`,
                        element
                    );
                }

                return match?.overlayNumberOfIndividuals || 0;
            })

            const chartHeight = `${(PLOT_WIDTH_BAR_STACKED * chartSourceData.length * 2) + HEADER_SIZE_BAR_STACKED}`;
            const series: any[] = [
                {
                    // color: reportColors.barIndex.index,
                    data: chartSourceData.map((element: any) => element.displayIndexScore),
                    name: 'Relative Index',
                    // threshold: 1,
                    type: 'bar',
                    yAxis: 1,
                    zones: reportColors.zone.relativeIndex,
                },
                {
                    color: reportColors.barIndex.count,
                    data: numberOfIndividualsData,
                    formatter: value => Number(value).toLocaleString(),
                    name: 'Number of Individuals',
                    yAxis: 0,
                    type: 'bar',
                },
            ];
            chartData = {
                id: chartId,
                filterApplied,
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    text: `${sortDirection === 'desc' ? 'Top' : 'Least Represented'} ${dataTypes.join('/')} Characteristics`,
                },
                plotOptions: Highcharts.merge({}, chartPlotOptions.barGroup, {
                    plotWidth: PLOT_WIDTH_BAR_STACKED,
                    series: {
                        groupPadding: 0.1,
                    },
                }),
                xAxis: {
                    // categories: chartSourceData.map((element: any) => element.shortDescription),
                    categories: dataElementCategories(chartSourceData),
                    gridLineWidth: 0,
                    labels: {
                        events: {
                            click: dataElementExcludeHandler,
                        },
                        // formatter: (point: any) => {
                        //     return `<div class="d-flex align-items-center flex-row-reverse">
                        //         <div class="">${dataElementExcludeLink(chartSourceData[point.pos], chartId)}</div>
                        //         <div class="text-right">${point.value}</div>
                        //     </div>`;
                        // },
                        formatter: (point: any) => dataElementCategoryPointFormatter(point, chartSourceData, chartId),
                        useHTML: true,
                    },
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis: [
                    {
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        margin: 0,
                        minorTickInterval: 0,
                        opposite: true,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            // text: 'Relative Index',
                            text: series[0].name,
                        },
                        type: 'logarithmic',
                    },
                    {
                        labels: {
                            style: {
                                color: reportColors.barIndex.countLabel,
                            },
                        },
                        margin: 0,
                        minorTickInterval: 0,
                        opposite: true,
                        title: {
                            style: {
                                color: reportColors.barIndex.count,
                            },
                            text: 'Number of Individuals',
                        },
                        type: 'logarithmic',
                        visible: false,
                    },
                ].reverse(),
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', {
                        includeHeader: true,
                        footer: chartSourceData.map((element: any) => {
                            return {
                                x: element.shortDescription,
                                value: element.longDescription,
                            }
                        }),
                    }),
                    outside: true,
                    useHTML: true,
                },
            };
        }
            break;

        default:
            console.error(`No matching handler for ${chartId}!`);
    }

    // // if (chartData.hasOwnProperty('tooltip')) {
    // //     chartData.tooltip = Highcharts.merge(defaultChartOptions.tooltip, chartData.tooltip);
    // // }
    // chartData.id = chartId;
    // if (!chartData.hasOwnProperty('chart') && !chartData.hasOwnProperty('series')) {
    //     console.error(`NO CHART DATA FOR CHART: ${chartId}`, chartData);
    //     return false;
    // }
    //
    // // Automatically apply a chart title using the field diectionary, if none was provided
    // if (!chartData.hasOwnProperty('title')) {
    //     let title;
    //     if (chartId.indexOf('|')) {
    //         const [key] = chartId.split('|');
    //         title = fieldDictionary[key]?.shortDescription;
    //     } else {
    //         title = fieldDictionary[chartId]?.shortDescription;
    //     }
    //
    //     chartData.title = {text: title || `NO TITLE FOUND FOR ${chartId}`};
    // }
    //
    // if (printMode) {
    //     chartData = Highcharts.merge(
    //         {
    //             chart: {
    //                 animation: false,
    //             },
    //             plotOptions: {
    //                 series: {
    //                     animation: false,
    //                 }
    //             },
    //         },
    //         chartData
    //     )
    // }

    if (!chartData.series) {
        console.error(`NO SERIES DEFINED FOR ${chartId}`);
    }

    return prepareChartDefaults(chartData, options);

    // return Highcharts.merge(defaultChartOptions, chartData);
}

export const prepareChartDefaults = (chartData: any, options: any) => {
    const {
        // asyncData,
        chartId,
        // filterGroup,
        // filterSettings,
        Highcharts,
        // insights,
        // params,
        printMode,
        // returnData,
        // sortGroup,
    } = options;


    // if (chartData.hasOwnProperty('tooltip')) {
    //     chartData.tooltip = Highcharts.merge(defaultChartOptions.tooltip, chartData.tooltip);
    // }
    chartData.id = chartId;
    if (!chartData.hasOwnProperty('chart') && !chartData.hasOwnProperty('series')) {
        console.error(`NO CHART DATA FOR CHART: ${chartId}`, chartData);
        return false;
    }

    if (!chartData.hasOwnProperty('title')) {
        let title;
        // if (chartId.indexOf('|')) {
        //     const [key] = chartId.split('|');
        //     title = fieldDictionary[key]?.shortDescription;
        // } else {
        //     title = fieldDictionary[chartId]?.shortDescription;
        // }

        chartData.title = {text: title || `NO TITLE FOUND FOR ${chartId}`};
    }

    if (printMode) {
        chartData = Highcharts.merge(
            {
                chart: {
                    animation: false,
                },
                plotOptions: {
                    series: {
                        animation: false,
                    }
                },
            },
            chartData
        )
    }

    return Highcharts.merge(defaultChartOptions, chartData);
}

export const scoredCharacteristics = (data: any[], scoreProperty: string = 'indexScore') => {
    // console.debug('SORT OVERALL CHARACTERISTICS');
    return data
        .map((element: any) => {
            // console.debug(`DETERMINING "${scoreProperty}" SORT SCORE FOR ELEMENT ${element.dataElementId} "${element.shortDescription}":`, element);
            switch (element.dataElementType) {
                case dataElementTypes.numerical:
                case dataElementTypes.index:
                    // // ORIGINAL PAGE-LEVEL SORT METHOD
                    // // Create a sliding score from top bins
                    // let binScore = 0;
                    // const binLength = element.statsDetail.length;
                    // element.statsDetail.forEach((detail: any, i: number) => {
                    //     binScore += (binLength - i) * detail[scoreProperty];
                    // });
                    // element.characteristicScore = binScore;

                    element.characteristicScore = decileWeightedIndexScore(element);
                    element.displayIndexScore = decileTopIndexScore(element);

                    // TODO: handle linearity and lift score...
                    break;

                default: {
                    // Use the highest value
                    const maxIndexScore = Math.max(...element.statsDetail.map((detail: any) => detail[scoreProperty] || null));
                    if (maxIndexScore) {
                        element.characteristicScore = maxIndexScore;
                        element.displayIndexScore = maxIndexScore;
                    }
                }
                    break;
            }
            if (!element.displayIndexScore) {
                console.warn(`Could not determine indexScore value for "${element.shortDescription}" (${element.dataElementType})`, element);
            }

            // element.displayIndexScore = element.displayIndexScore || 0;
            element.maxMedianValue = Math.max(...element.statsDetail.map((detail: any) => detail.medianValue));

            return element;
        })
        .filter((element: any) => element.hasOwnProperty('characteristicScore'));
}

export const socialActionLabel = (account: any) => {
    let accountName = '',
        accountUrl = socialActionUrl(account);
    switch (account.dataType) {
        case 'twitterFollow':
            accountName = `Follows <a title="${account.displayName}" target="_new" href="${accountUrl}">${account.displayName}</a>`;
            break;
        case 'twitterHashtag':
            accountName = `Used ${account.item}`;
            break;
        case 'twitterMention':
            accountName = `Mentioned <a title="${account.displayName}" target="_new" href="${accountUrl}">${account.displayName}</a>`;
            break;
        case 'twitterTerm':
            accountName = `Tweeted ${account.item}`;
            break;
        default:
            console.error(`Unknown social label data type for "${account.displayName || account.item}" -  ${account.dataType}`, account);
            break;
    }

    return accountName;
}

export const socialActionIcon = (account: any, params: any = {}) => {
    let iconParameters: any = {
        context: null,
        icon: null,
        useImage: false,
    };

    switch (account.dataType) {
        case 'twitterFollow':
            iconParameters.context = 'twitter';
            iconParameters.useImage = true;
            break;

        case 'twitterHashtag':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fas fa-hashtag';
            iconParameters.useImage = false;
            break;

        case 'twitterMention':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fas fa-at';
            iconParameters.useImage = false;
            break;

        case 'twitterTerm':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fas fa-quote-left';
            iconParameters.useImage = false;
            break;

        default:
            console.debug(`Unknown social icon data type for "${account.displayName || account.item}" - ${account.dataType}`, account);
    }

    return iconParameters.useImage ?
        `<div class="${params?.class || ''}" style="${params?.style || ''}">
            <img alt="${account.displayName || account.item}"
                 class="img-fluid fallback-image-${iconParameters.context}"
                 onerror="${Utils.imageFallback(iconParameters.context)}"
                 src="${account.imageUrl}"
            />
        </div>` :
        `<div class="social-action-indicator ${Utils.kebabCase(account.dataType)} ${params?.class || ''}"
              style="${params?.style || ''}"
            >
            <i class="${iconParameters.icon} fa-fw"></i>
        </div>`;
}

export const socialActionUrl = (account: any) => {
    const accountName = account.item.replace('@', '');

    // Currently only twitter items are linked
    return ['twitterFollow', 'twitterMention'].includes(account.dataType) ?
        `https://twitter.com/${accountName}` :
        null;
}

export const tooltipFormatter = (type?: string, specifiedOptions?: any): Highcharts.TooltipFormatterCallbackFunction => {
    const options = Object.assign({
        includeHeader: false
    }, specifiedOptions);

    let headerCallback = (typeof options.includeHeader === 'boolean' && !options.includeHeader) ?
        () => {
            return '';
        } :
        label => {
            return label;
        };
    if (typeof options.includeHeader === 'string') {
        if (options.includeHeader.match(/^string:/)) {
            headerCallback = _ => {
                options.includeHeader
            };
        } else {
            // Use property
            headerCallback = obj => {
                return obj[options.includeHeader];
            }
        }
    }

    switch (type) {
        case 'affinity':
            return function () {
                const account: any = this.x;
                let str = '';

                this.points!.forEach((point) => {
                    const value = point.y;
                    let verbPresentTense = '',
                        verbPastTense = ''
                    // @ts-ignore
                    switch (point.x.dataType) {
                        case 'twitterFollow':
                            verbPresentTense = 'follow';
                            verbPastTense = 'followed';
                            break;
                        case 'twitterHashtag':
                            verbPresentTense = 'use';
                            verbPastTense = 'used';
                            break;
                        case 'twitterMention':
                            verbPresentTense = 'mention';
                            verbPastTense = 'mentioned';
                            break;
                        case 'twitterTerm':
                            verbPresentTense = 'tweet';
                            verbPastTense = 'tweeted';
                            break;
                    }

                    switch (point.series.userOptions.yAxis) {
                        case 0: // Relative index
                            str += `<span style="color: ${point.color}">\u25CF</span>
                                    People in this Persona are <strong style="color: ${point.color}">${Utils.formatValue(value, 'indexLikelihood', 1)}</strong> to ${verbPresentTense} <strong>${account.displayName}</strong><br>
                                    <span style="opacity: 0">\u25CF</span>
                                    <em style="font-size: 90%">as the PersonaBuilder baseline</em><br>`;
                            break;
                        case 1: // Follow count
                            str += `<span style="color: ${point.color}">\u25CF</span>
                                    <strong style="color: ${point.color}">${value.toFixed(2)}%</strong> of people in this Persona ${verbPastTense} <strong>${account.displayName}</strong><br>`;
                            break;
                        case 2: // Tweet count
                            if (value) {
                                str += `<span style="color: ${point.color}">\u25CF</span>
                                        Together, they ${verbPastTense} it <strong style="color: ${point.color}">${Utils.formatValue(value, 'separated', 0)}</strong> times in total<br>`;
                            }
                            break;
                    }
                });

                return str;
            };

        case 'demographicSummary':
            return function () {
                const indexValue = this.point.series['linkedSeries'][0].data[this.point.index].y;

                return `
                    <strong>${this.series.userOptions.name}</strong><br>
                    <span style="color: ${this.point.color}">\u25CF</span> ${this.key}: ${Utils.formatValue(this.y, 'percent')}<br>
                    ${Utils.formatValue(indexValue, 'indexLikelihood')}
                `;
            };

        case 'flex':
            // A flexible tooltip formatter driven by properties included in the series
            return function () {
                let str = '';
                const points = (this.points || [this.point]);
                points.forEach((point: any) => {
                    if (point.series.userOptions['showInTooltip'] === false) {
                        return;
                    }

                    let formattedValue;
                    if (point.series.userOptions.hasOwnProperty('formatter')) {
                        // @ts-ignore
                        formattedValue = point.series.userOptions.formatter(point.y);
                    } else {
                        formattedValue = point.y.toFixed(2);
                    }
                    str += `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: <strong>${formattedValue}</strong><br>`;
                });

                if (options.includeHeader) {
                    str = `${headerCallback(this.x)}<br>${str}`;
                }

                if (points[0].series.options.custom?.hasOwnProperty('additionalTooltipInformation')) {
                    const additionalInfo = points[0].series.options.custom.additionalTooltipInformation;
                    let additionalInfoParts: string[] = [];
                    if (additionalInfo.hasOwnProperty('color')) {
                        additionalInfoParts.push(`<span style="color: ${additionalInfo.color}">\u25CF</span>`);
                    }
                    if (additionalInfo.hasOwnProperty('name')) {
                        additionalInfoParts.push(`${additionalInfo.name}:`);
                    }
                    if (additionalInfo.hasOwnProperty('value')) {
                        additionalInfoParts.push(`<strong>${additionalInfo.value}</strong>`);
                    }
                    str += additionalInfoParts.join(' ');
                    // str += `<span style="color: ${additionalInfo.color}">\u25CF</span> ${additionalInfo.name}: <strong>${additionalInfo.value}</strong><br>`;
                }

                if (options.hasOwnProperty('footer')) {
                    // TODO: allow global (non-positional) footer info that applies to every item?
                    const footer = options.footer.find((item: any) => item.x === this.x);
                    if (footer) {
                        str += `<div class="footer">${footer.value}</div>`;
                    }
                }

                return str;
            };

        case 'map':
            return function () {
                let str = `
                    <strong>${this.point.options.name}</strong><br>
                    Relative Index: ${(this.point['indexScore'] || this.point['value']).toFixed(2)}x<br>
                    Customer File: ${this.point['overlayPercent'].toFixed(2)}%
                `;

                if (options.includeHeader) {
                    str = `${headerCallback(this.point)}<br>${str}`;
                }

                return str;
            };

        case 'percent':
            return function () {
                let header = this.x;
                let str = '';

                this.points!.forEach((point) => {
                    if (point.series.userOptions['showInTooltip'] === false) {
                        return;
                    }

                    let formattedValue = point.y.toFixed(2);
                    if (point.series.name.indexOf('Index') === -1) {
                        formattedValue = `${formattedValue}%`;
                    }
                    str += `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: <strong>${formattedValue}</strong><br>`;
                });

                if (options.includeHeader) {
                    str = `${header}<br>${str}`
                }

                return str;
            };

        case 'piePercent':
            return function () {
                return `<span style="color: ${this.color}">\u25CF</span> ${this.series.name}: <strong>${this.y.toFixed(2)}% ${this.key} </strong>`;
            };

        default:
            return function () {
                return `<span style="color: ${this.points![0].color}">\u25CF</span> ${this.x}: <strong>${this.y.toFixed(2)}</strong>`;
            };
    }
}
